import { ReactComponent as legal } from './svg/legal.svg';
import { ReactComponent as references } from './svg/references.svg';
import { ReactComponent as info } from './svg/info.svg';

export const APP_KEY = 'dpvT9tt5DyVqhQ6hS0aviWD2VzqunAar';
export const REF_NUMBER = 'ESI-FR-P-WS06/02.19 - 19/04/66153192/PM/001';
export const FOOTER_HEIGHT = 55;
export const FOOTER_HEIGHT_MOBILE_LANDSCAPE = 35;
export const MIN_LOADING_TIME = 1000;
export const SLIDER_DEFAULT_DURATION = 15;
export const SLIDER_DEFAULT_VALUE = 10;
export const SLIDER_DEFAULT_MIN = 0;
export const SLIDER_DEFAULT_MAX = 35;
export const SLIDER_DEFAULT_STEP = 0.5;

export const VIDEOS_WITH_CHESS_BUTTONS = ['03A', '18', '23A'];

export const Icons = { legal, references, info };

export const Colors = {
  BLUE: '#2d4150',
  BLACK: '#000000',
  DARK_BLUE: '#2f3c44',
  WHITE: '#ffffff',
  RED_BRIGHT: '#e20613',
  RED: '#cc2731',
  TRANSPARENT_BLUE: '#2d4150a6',
  TRANSPARENT_WHITE: '#ffffff80',
  TRANSPARENT_RED: '#e2061380',
  SHADOWS: '#0000007a',
}

export const ChoiceType = {
  QUESTION: 'QUESTION',
  CHESS: 'CHESS',
  SLIDER: 'SLIDER',
}

export const Videos = {};

export const SCROLLBAR_CONTAINER_STYLE = {
  width: 4,
  borderRadius: 2,
}

export const SCROLLBAR_STYLE = {
  backgroundColor: Colors.RED,
  width: '100%',
  marginLeft: 0,
  borderRadius: 2,
}