import React, { useContext, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { isMobile } from 'react-device-detect';

import useDeviceOrientation from '../hooks/useDeviceOrientation';
import { HTAContext } from '../App';
import { Colors, FOOTER_HEIGHT, ChoiceType, Videos, SLIDER_DEFAULT_DURATION, VIDEOS_WITH_CHESS_BUTTONS } from '../Constants';
import { ReactComponent as Arrow } from '../svg/arrow.svg';
import Popup from './layout/Popup';
import RedCorner from './layout/RedCorner';
import Timer from './Timer';
import Video from './Video';
import Slider from './Slider';
import { vw, vh } from '../utils';
import Sound from '../Sound';
import { KNOWS_RULES } from '../context/hta-context';

const Choice = ({ step }) => {
  const history = useHistory();
  const orientation = useDeviceOrientation();

  const { t } = useTranslation('game');
  const [choiceState, setChoiceState] = useState('video');
  const [showPopup, setShowPopup] = useState(false);
  const [popupTimePassed, setPopupTimePassed] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [showSliderCorrect, setShowSliderCorrect] = useState(true);
  const [chosenAnswer, setChosenAnswer] = useState(-1);
  const [videoTime, setVideoTime] = useState(0);
  const [ratio, setRatio] = useState(window.innerWidth / window.innerHeight);
  
  const { state, dispatch } = useContext(HTAContext);
  const { choices, popup: showExternalPopup, knowsRules: playerKnowsRules } = state;
  const choice = choices[step];
  const { title, question, type, options, popupTimeShow, duration: choiceDuration, sliderValue, knowsRules } = choice;
  let { video } = choice;
  const duration = type === ChoiceType.SLIDER ? (choiceDuration > 0 ? choiceDuration : SLIDER_DEFAULT_DURATION) : choiceDuration;
  const defaultOption = options.findIndex(option => option.default) || 0;
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  if (video.includes('/')) {
    video = video.split('/')[playerKnowsRules ? 0 : 1];
  }

  // Callbacks
  const onOptionChosen = useCallback(i => {
    Sound.ChoiceValid();
    setVideoTime(0);
    setChosenAnswer(i);
    setChoiceState('answer');
    setTimeout(() => {
      setChosenAnswer(-1);
      setChoiceState('video');
      history.push(`/game/${options[i].to}`);
    }, 1000);
  }, [history, options]);
  const onVideoEnded = useCallback(() => {
    if (options.length > 0) {
      setChoiceState('question');
    } else {
      history.push(`/`);
    }
  }, [options.length, history]);
  const closePopup = useCallback(() => setShowPopup(false), []);
  const onTimeUp = useCallback(() => {
    setTimeUp(true);
    if (type === ChoiceType.SLIDER && showSliderCorrect) {
      
    } else {
      onOptionChosen(defaultOption);
    }
  }, [defaultOption, onOptionChosen, showSliderCorrect, type]);
  const onVideoProgress = useCallback(e => setVideoTime(e.playedSeconds), []);
  const onSliderWin = useCallback(() => { onOptionChosen(defaultOption); }, [onOptionChosen, defaultOption]);
  const onBodyKeyDown = useCallback(
    ({key}) => {
      if (process.env.NODE_ENV === 'development' && key === 'Enter') {
        onVideoEnded();
      }

      if (showExternalPopup) {
        return;
      }
      let newOption = 0;
      if (key === 'ArrowLeft') {
        newOption = selectedOption - 1;
        setSelectedOption(newOption >= 0 ? newOption : options.length - 1);
      } else if (key === 'ArrowRight') {
        newOption = selectedOption - 1;
        setSelectedOption(newOption >= 0 ? newOption : options.length - 1);
      }
    },
    [options.length, selectedOption, showExternalPopup, onVideoEnded],
  )
  const onChoiceButtonKeyDown = useCallback(
    ({ key }) => {
      if (!showExternalPopup && key === 'Enter') {
        onOptionChosen(selectedOption);
      }
    },
    [onOptionChosen, selectedOption, showExternalPopup],
  )

  // Reset the video player every time we change the step
  useEffect(() => {
    if (video && Videos[video]) {
      setChoiceState('video');
      setShowPopup(!popupTimeShow);
    } else {
      setChoiceState('question');
    }
    setVideoTime(0);
    setSelectedOption(-1);
    setPopupTimePassed(false);
    setTimeUp(false);
    setShowSliderCorrect(true);

    if (knowsRules) {
      dispatch({ type: KNOWS_RULES });
    }

    return () => {
      Sound.fade(Sound.AmbQuestion, true)
    }
  }, [step, defaultOption, popupTimeShow, video, knowsRules, dispatch]);
  useEffect(() => {
    if (choiceState === 'question') {
      document.getElementById(`choice-${selectedOption}`) && document.getElementById(`choice-${selectedOption}`).focus();
      document.body.addEventListener('keydown', onBodyKeyDown, false);
    } else if (process.env.NODE_ENV === 'development') {
      document.body.addEventListener('keydown', onBodyKeyDown, false);
    }
    return () => {
      document.body.removeEventListener('keydown', onBodyKeyDown, false);
    }
  }, [choiceState, onBodyKeyDown, selectedOption]);
  useEffect(() => {
    if (!showPopup && popupTimeShow && popupTimeShow <= videoTime && !popupTimePassed) {
      setPopupTimePassed(true);
      setShowPopup(t(`popup-${title}`) !== `popup-${title}`); // If the popup exists, show it now
    }
  }, [showPopup, popupTimeShow, videoTime, popupTimePassed, t, title]);
  
  // Sound and autoplay
  useEffect(() => {
    if (choiceState === 'question') {
      Sound.ChoiceAppear();
      Sound.AmbQuestion.currentTime = 0;
      Sound.fade(Sound.AmbQuestion, false, 0.5)
    } else if (choiceState === 'video') {
      Sound.AmbQuestion.pause();
      Sound.AmbQuestion.currentTime = 0;
    } else if (choiceState === 'answer') {
      Sound.fade(Sound.AmbQuestion, true)
    }
  }, [choiceState]);

  // Animations
  const questionTitleStyle = useSpring({ opacity: choiceState === 'question' ? 1 : 0 });
  const buttonsStyles = [
    useSpring({ left: (choiceState === 'question' || chosenAnswer === 0) ? 0 : vw((isMobile && orientation === 'portrait') ? -100 : -50), top: 0 }),
    useSpring((isMobile && orientation === 'portrait')
      ? { left: (choiceState === 'question' || chosenAnswer === 1) ? 0 : vw(-100), top: 0, config: { tension: 150 } }
      : options.length <= 2 ? { left: (choiceState === 'question' || chosenAnswer === 1) ? 0 : vw(50), top: 0 } : { top: (choiceState === 'question' || chosenAnswer === 1) ? 0 : vh(50), left: 0 }),
    useSpring((isMobile && orientation === 'portrait')
      ? { left: (choiceState === 'question' || chosenAnswer === 2) ? 0 : vw(-100), top: 0, config: { tension: 110 } }
      : { left: (choiceState === 'question' || chosenAnswer === 2) ? 0 : vw(50), top: 0 }),
  ]
  const animPopupContainer = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });

  const onResize = useCallback(() => setRatio(window.innerWidth / window.innerHeight), []);
  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, [onResize]);

  const topLeftCorner = { x: 0, y: 0 };
  if ((!isMobile || orientation === 'landscape') && VIDEOS_WITH_CHESS_BUTTONS.includes(video)) {
    if (ratio < 16/9) {
      topLeftCorner.x = (window.innerWidth - window.innerHeight * 16/9) / 2;
    } else {
      topLeftCorner.y = (window.innerHeight - window.innerWidth / (16/9)) / 2;
    }
  }
  
  return (
    <Wrapper className='wrapper-absolute'>
      <InnerWrapper style={animPopupContainer} id='choice-wrapper'>
        { !!video && !!Videos[video] &&
          <Video step={step} videoData={Videos[video]} playing={choiceState === 'video'} onVideoProgress={onVideoProgress} onEnded={onVideoEnded} />
        }

        <PopupContainer>
          { showPopup && <Popup id={title} onClose={closePopup} /> }
        </PopupContainer>

        { (choiceState === 'question' || choiceState === 'answer') &&
          <React.Fragment>
            { !!question &&
            <QuestionTitle style={questionTitleStyle} type={type}>{ t(question).split('\n').map((line, i) =>
              <div key={`question-line-${i}`}>{line}</div>) }
              { choiceState === 'question' && !!duration && duration > 0 && !timeUp && <Timer type='line' duration={duration} /> }
            </QuestionTitle> }

            { (options.length > 0) &&
                <ButtonsWrapper type={type}>
                {
                  type === ChoiceType.SLIDER
                  ? <Slider onWin={onSliderWin} showCorrect={timeUp && showSliderCorrect} correctValue={sliderValue} />
                  : <React.Fragment>
                    {
                      // If the question is a chess question that shows buttons on the chess board, we won't display those buttons
                      (!VIDEOS_WITH_CHESS_BUTTONS.includes(video) || (isMobile && orientation === 'portrait')) &&
                      options.map((option, i) =>
                        <ChoiceButton style={buttonsStyles[i]} tabIndex={i + 1} className={classnames({'on': chosenAnswer === i})} type={type} id={`choice-${i}`} key={`choice-${i}`} onKeyDown={onChoiceButtonKeyDown} onClick={() => onOptionChosen(i)} onMouseOver={Sound.ChoiceHover}>
                          { t(option.title) }
                          <Corner className='no-mobile' />
                          <Corner className='no-mobile' top={true} />
                          <BTArrow className='no-mobile-landscape' />
                        </ChoiceButton>
                      )
                    }
                  </React.Fragment>
                  }
                </ButtonsWrapper>
            }
          </React.Fragment>
        }

        { choiceState === 'question' && !!duration && duration > 0 && !timeUp && <Timer key={step} type='clock' duration={duration} onTimeUp={onTimeUp} /> }
        { (choiceState === 'question' && VIDEOS_WITH_CHESS_BUTTONS.includes(video) && (!isMobile || orientation === 'landscape')) &&
          <ChessButtons style={{
            left: topLeftCorner.x,
            top: topLeftCorner.y,
            width: (ratio < 16/9) ? window.innerHeight * (16/9) : window.innerWidth,
            height: (ratio < 16/9) ? window.innerHeight : window.innerWidth / (16/9) }}>
            <div className="inner-wrapper">
              {
                video === '03A' &&
                  <React.Fragment>
                    <ChessButton onClick={() => onOptionChosen(0)} style={{ left: "41%", top: '72.1%', width: '7.6%', height: '11%' }} />
                    <ChessButton onClick={() => onOptionChosen(1)} style={{ left: "35.7%", top: '20%', width: '6.5%', height: '8.1%' }} />
                  </React.Fragment>
              }
              {
                video === '18' &&
                  <React.Fragment>
                    <ChessButton onClick={() => onOptionChosen(0)} style={{ left: "29.38%", top: '43.89%', width: '7.03%', height: '9.26%' }} />
                    <ChessButton onClick={() => onOptionChosen(1)} style={{ left: "55.83%", top: '52.96%', width: '7.92%', height: '10.37%' }} />
                  </React.Fragment>
              }
              {
                video === '23A' &&
                  <React.Fragment>
                    <ChessButton onClick={() => onOptionChosen(0)} style={{ left: "55.2%", top: '27.5%', width: '6.98%', height: '8.98%' }} />
                    <ChessButton onClick={() => onOptionChosen(1)} style={{ left: "49.4%", top: '72.87%', width: '7.7%', height: '11%' }} />
                  </React.Fragment>
              }
            </div>
          </ChessButtons> }
      </InnerWrapper>
    </Wrapper>
  )
}
export default React.memo(Choice);

const Wrapper = styled.div`
  @media screen and (max-width: 768px) and (orientation: portrait) {
    bottom: ${`${FOOTER_HEIGHT + 52}px`};
  }
`;
const InnerWrapper = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) and (orientation: portrait){
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${Colors.TRANSPARENT_BLUE};
  }
`;
const QuestionTitle = styled(animated.div)`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.TRANSPARENT_WHITE};
  background-color: ${Colors.TRANSPARENT_BLUE};
  padding: 1rem calc(10px + 3vmin);
  transform: translate(-50%, -100%);
  position: absolute;
  left: 50%;
  top: 60%;
  min-width: 280px;
  user-select: none;
  max-width: 30%;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};

  & > div {
    margin: 0.5rem;
  }

  ${props => props.type === ChoiceType.CHESS && css`
    top: 20px;
    transform: translate(-50%, 0%);
  `};

  @media screen and (max-width: 900px) and (orientation: landscape) {
    box-shadow: none;
    padding: 0.5rem calc(5px + 1.5vmin);
    transform: translate(-50%, 0%);
    top: 15%;
    min-width: 200px;
    max-width: 80%;
    z-index: 10;

    & > div {
      margin: 0.1rem;
    }
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    box-shadow: none;
    max-width: 100%;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    transform: none;
    min-width: auto;
    overflow: visible;
    z-index: 2;
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 70%;
  width: 70%;

  ${props => props.type === ChoiceType.CHESS && css`
    top: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    justify-content: space-between;
  `};

  ${props => props.type === ChoiceType.SLIDER && css`
    display: block;
    width: 40%;
  `};

  @media screen and (max-width: 768px) and (orientation: portrait) {
    position: initial;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    transform: none;
    width: 100%;
    top: auto;
    left: 0;
    right: 0;

    ${props => props.type === ChoiceType.SLIDER && css`
      border: 1px solid ${Colors.TRANSPARENT_WHITE};
      background-color: ${Colors.TRANSPARENT_BLUE};
      height: 110px;
    `};
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: 90%;
    top: 50%;
    z-index: 10;

    ${props => props.type === ChoiceType.CHESS && css`
      width: 80%;
    `};

    ${props => props.type === ChoiceType.SLIDER && css`
      display: block;
      width: 60%;
    `};
  }
`;

const ChoiceButton = styled(animated.span)`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.TRANSPARENT_WHITE};
  background-color: ${Colors.TRANSPARENT_BLUE};
  padding: 1.5rem calc(2.75rem + 2vmin);
  cursor: pointer;
  user-select: none;
  flex-grow: 0.25;
  flex-basis: 0;
  outline: none;

  ${props => props.type === ChoiceType.CHESS && css`
    flex-grow: 0.1;
  `};

  :active {
    text-shadow: none;
    background-color: ${Colors.TRANSPARENT_BLUE};
  }

  @media screen and (min-width: 769px) {
    box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};
    :hover,
    :focus {
      font-weight: bold;
      text-shadow: 0px 0px 10px #CECECE;
      background-color: ${Colors.BLUE};
      border-right: none;
    }

    &.on {
      font-weight: bold;
      text-shadow: 0px 0px 10px #CECECE;
      background-color: ${Colors.BLUE};
      border-right: none;
    }
  }
  
  @media screen and (max-width: 900px) and (orientation: landscape) {
    padding: 1rem calc(0.5rem + 0.5vmin);
    font-weight: bold;
    ${props => props.type === ChoiceType.CHESS && css`
      flex-grow: 0.25;
    `};
  }

  @media screen and (max-width: 768px) and (min-width: 426px) and (orientation: portrait) {
    flex-grow: 1;
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    padding: 1rem 0;
  }

`;

const BTArrow = styled(Arrow)`
  pointer-events: none;
  width: 2rem;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 1.5rem;
  transition: transform 0.15s ease-in-out;

  @media screen and (min-width: 769px) {
    span.on > &,
    span:focus > &,
    span:hover > & {
      transform: translate(calc(100% + 0.75rem), -50%);
    }
  }
`;

const Corner = styled(RedCorner)`
  span.on > &,
  span:focus > &,
  span:hover > & {
    width: 3.5rem;
    height: 25%;
  }
`;

const PopupContainer = styled(animated.div)`
  flex: 1;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 768px) and (orientation: portrait){
    position: relative;
  }
`;

const ChessButtons = styled.div`
  position: fixed;
  z-index: 2;
`;

const ChessButton = styled.div`
  position: absolute;
  cursor: pointer;
`;