import i18n from "i18next";
import common_fr from "./translations/fr/common.json";
import game_fr from "./translations/fr/game.json";
import legal_fr from "./translations/fr/legal";
import references_fr from "./translations/fr/references";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping,
    lng: 'fr',
    fallbackLng: 'fr',
    defaultNS: 'common',
    react: {
      useSuspense: false
    },
    resources: {
      fr: {
        common: common_fr,
        game: game_fr,
        html: {
          legal: legal_fr,
          references: references_fr,
        }
      }
    }
  });

export default i18n;