import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { ReactComponent as Juvise } from '../../svg/juvise.svg';
import { ReactComponent as HTALogo } from '../../svg/hta-logo.svg';
import { useSpring, animated } from 'react-spring';

const Header = props => {
  const location = useLocation();
  const history = useHistory();
  const showLogo = props.showLogo || /^\/game\/*/.test(location.pathname);
  const animJuvise = useSpring({ from: { transform: 'translateX(100%)' }, to: { transform: 'translateX(0)' } });
  const animLogo = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });

  const onLogoClick = useCallback(() => history.push('/'), [history]);

  return (
    <Wrapper showLogo={showLogo} className='App-header'>
      { !!showLogo && <animated.span onClick={onLogoClick} className='hta-logo' style={Object.assign({ marginTop: 20, marginLeft: 'calc(10px + 3vmin)' }, animLogo)}>
        <HTALogo style={{ height: 'calc(20px + 3vmin)', maxHeight: 40 }} />
      </animated.span> }
      <animated.span style={Object.assign({ padding: '20px calc(10px + 3vmin) 6px 14px', borderBottom: '1px solid white', position: 'relative' }, animJuvise)}>
        <Juvise style={{ height: 'calc(10px + 4vmin)', maxHeight: 40 }} />
      </animated.span>
    </Wrapper>
  )
}
Header.propTypes = {
  showLogo: PropTypes.bool
}
export default React.memo(Header);
const Wrapper = styled.header`

  & .hta-logo {
    pointer-events: all;
  } 

  & .hta-logo:hover {
    cursor: pointer;
  } 

  ${props => props.showLogo && css`
    justify-content: space-between;
  `}

  @media screen and (max-width: 768px) and (orientation: portrait),
    screen and (max-width: 900px) and (orientation: landscape) {
    .App.game & {
      opacity: 0.75;
    }
  }

`;