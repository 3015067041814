import React, { useCallback, useRef, useEffect } from 'react'
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSpring, useChain, animated } from 'react-spring';

import bracket from '../svg/bracket.svg';
import { ReactComponent as Esidrex } from '../svg/esidrex.svg';
import { ReactComponent as HTALogoSVG } from '../svg/hta-logo.svg';
import { ReactComponent as VolumeSVG } from '../svg/volume.svg';
import { Colors } from '../Constants';
import RedCorner from './layout/RedCorner';
import Sound from '../Sound';
import PlayButton from './PlayButton';

const MainMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // Callbacks
  const onStart = useCallback(() => {
    Sound.Start();
    history.push('/game/1');
  }, [history]);

  const wrapper = useRef();
  const esidrexAnimRef = useRef();
  const subtextAnimRef = useRef();
  const subSubtextAnimRef = useRef();
  const logoAnimRef = useRef();
  const textAnimRef = useRef();
  const startBTAnimRef = useRef();
  const startBTTextAnimRef = useRef();

  const esidrexAnim = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 500 }, ref: esidrexAnimRef });
  const subtextAnim = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 500 }, ref: subtextAnimRef });
  const subSubtextAnim = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 500 }, ref: subSubtextAnimRef });
  const logoAnim = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 500 }, ref: logoAnimRef });
  const textAnim = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 500 }, ref: textAnimRef });
  const startBTAnim = useSpring({ from: { transform: 'scale(0.2)', opacity: 0, pointerEvents: 'none' }, to: { transform: 'scale(1)', opacity: 1, pointerEvents: 'all' }, ref: startBTAnimRef });
  const startBTTextAnim = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, ref: startBTTextAnimRef });

  useChain([esidrexAnimRef, subtextAnimRef, subSubtextAnimRef, logoAnimRef, textAnimRef, startBTAnimRef, startBTTextAnimRef], [0, 0, 0, 1, 1, 2, 2.5]);

  useEffect(() => {
    // Make sure the ambient sound from the game isn't playing
    Sound.fade(Sound.AmbQuestion, true);
    // Play the ambient for the main menu
    Sound.AmbMain.currentTime = 0;
    // Sound.fade(Sound.AmbMain, false, 0.5);
    setTimeout(Sound.StartBtAppear, 2000);
    return () => {
      // Sound.fade(Sound.AmbMain, true);
    }
  }, []);

  return (
    <Wrapper ref={wrapper} className='wrapper-absolute'>
      <div className='inner-wrapper'>
        <LogoContainer>
          <EsidrexLogo style={esidrexAnim}><Esidrex style={{ height: '100%' }} /></EsidrexLogo>
          <animated.p style={Object.assign({ textTransform: 'uppercase', color: Colors.RED_BRIGHT, fontWeight: 'bold' }, subtextAnim)}>{ t('Pièce maîtresse') }</animated.p>
          <animated.p style={Object.assign({ textTransform: 'uppercase', fontWeight: 'bold' }, subSubtextAnim)}>{ t('de la prise en charge de l\'HTA') }</animated.p>
        </LogoContainer>
        <MainWrapper>
          <HTALogo style={logoAnim}><HTALogoSVG style={{ height: '100%' }} /></HTALogo>
          <StartGameWrapper>
            <Text style={textAnim}>{ t('main_menu_text') }</Text>
            <PlayButton style={startBTAnim} onMouseOver={Sound.ChoiceHover} onClick={onStart}>
              <animated.div style={startBTTextAnim} className='start-bt-title no-mobile-portrait'>{ t('Commencer') }<br/>{ t('la partie') }</animated.div>
              <Info className='info no-mobile'>
                <ArrowTop viewBox='0 0 100 50'>
                  <path d='M 0,50 L 50,0 L 100,50' />
                </ArrowTop>
                <Border />
                <Border right={true} />
                <VolumeSVG className='volume' />
                <span>{ t('Activez le son pour une expérience optimale') }</span>
                <RedCorner className='red-corner' left={true} />
              </Info>
            </PlayButton>
          </StartGameWrapper>
        </MainWrapper>
      </div>
    </Wrapper>
  )
}
export default React.memo(MainMenu);

const Wrapper = styled.div`
  text-align: left;
  user-select: none;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    & > .inner-wrapper {
      background-color: rgba(45, 65, 80, 0.4);
    }
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  pointer-events: none;
  top: calc(10px + 4.5vmin);
  left: calc(20px + 7vmin);
  font-family: Gotham;

  & > p {
    margin: 0;
  }

  & > p:first-of-type {
    font-size: calc(10px + 2vmin);
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    & > p {
      font-size: calc(1px + 2vmin);
    }
    & > p:first-of-type {
      font-size: calc(3px + 2.5vmin);
    }
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    left: calc(10px + 3vmin);
    top: calc(10px + 3vmin);
    
    & > p {
      font-size: calc(8px + 1vmin);
    }
    & > p:first-of-type {
      font-size: calc(8px + 1vmin);
    }
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    & > p {
      font-size: calc(6px + 0.5vmin);
    }
  }
`;

const EsidrexLogo = styled(animated.div)`
  height: calc(20px + 3vmin);
  margin-bottom: calc(5px + 2vmin);

  @media screen and (max-width: 920px) and (orientation: landscape) {
    margin-bottom: calc(2px + 1vmin);
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    height: calc(30px + 1.5vmin);
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    height: calc(20px + 1.5vmin);
  }
`;

const MainWrapper = styled.div`
  position: absolute;
  top: 35%;
  left: calc(20px + 7vmin);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 920px) and (orientation: landscape) {
    right: calc(20px + 14vmin);
    top: 38%;
  }
  
  @media screen and (max-width: 768px) and (orientation: portrait) {
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    align-items: stretch;
  }
`;

const HTALogo = styled(animated.div)`
  height: calc(20px + 11.5vmin);
  max-height: 150px;
  margin-bottom: 2vmin;

  & .hta-logo-cls-1 {
    fill: ${Colors.BLACK};
    stroke: ${Colors.BLACK};
  }

  & .hta-logo-cls-2 {
    fill: ${Colors.RED_BRIGHT};
    stroke: ${Colors.RED_BRIGHT};
  }
  @media screen and (max-width: 768px) and (orientation: portrait) {
    height: calc(50px + 11vmin);
  }
`;

const StartGameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (min-width: 769px) {
    width: 33vw;
    min-width: 580px;
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    flex-direction: column;
  }
`;

const Text = styled(animated.p)`
  pointer-events: none;
  background-image: url(${bracket});
  background-repeat: no-repeat;
  position: relative;
  left: calc(-15px - 1vmin);
  padding: calc(15px + 1vmin) calc(15px + 2vmin);
  width: 18vmin;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  line-height: calc(12px + 1vmin);

  @media screen and (max-width: 768px) and (orientation: portrait) {
    padding: 0;
    width: auto;
    text-align: center;
    flex: 1;
    position: initial;
    margin-top: calc(50px + 8vh);
    background-image: none;
    font-size: calc(10px + 1.25vmin);
    line-height: calc(12px + 1.25vmin);
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    width: 40vw;
    margin: 0;
    background-image: none;
    left: 0;
    padding: 0;
  }

  @media screen and (max-width: 425px) and (orientation: landscape) {
    font-size: calc(8px + 0.5vmin);
    line-height: calc(10px + 0.5vmin);
  }
`;


const Info = styled.div`
  opacity: 0;
  pointer-events: none;
  border: solid 1px ${Colors.WHITE};
  border-top: none;
  width: calc(80px + 20vmin);
  height: calc(40px + 10vmin);
  position: absolute;
  bottom: -3.5rem;
  left: 50%;
  transform: translate(-50%, 100%) scale(0);
  transform-origin: center top;
  background-color: ${Colors.BLUE}44;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.15s ease-in;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};

  & > svg.volume {
    height: calc(8px + 1vmin);
    margin-bottom: 2vmin;
  } 

  & > span {
    width: 70%;
  }

  & > .red-corner {
    width: 1rem;
    height: 1rem;
  }
`;

const Border = styled.div`
  border-top: 1px solid ${Colors.WHITE};
  position: absolute;
  top: 0;
  left: ${props => props.right ? 'auto' : 0};
  right: ${props => props.right ? 0 : 'auto'};
  width: 45%;
`;
const ArrowTop = styled.svg`
  width: 10%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  margin: 0;
  overflow: visible;

  & > path {
    stroke-width: 3px;
    stroke: ${Colors.WHITE};
    fill: ${Colors.BLUE}44;
  }
`;