import React from 'react';

export default () => <React.Fragment><h2>COMPOSITION</h2>
<p>Hydrochlorothiazide 25 mg pour un comprimé sécable. Excipients&nbsp;: Lactose, amidon de blé, silice colloïdale purifiée, talc, stéarate de magnésium.</p>
<p>Excipient(s) à effet notoire&nbsp;: Chaque comprimé contient 50 mg de lactose et 49 mg d’amidon de blé.</p>
<h2>FORME PHARMACEUTIQUE</h2>
<p>Comprimé sécable.</p>
<p>Le comprimé peut être divisé en deux demi-doses égales.</p>
<h2>Indications thérapeutiques</h2>
<ul> <li>Œdèmes d'origine cardiaque ou rénale.</li> <li>Œdèmes d'origine hépatique, le plus souvent en association avec un diurétique épargneur de potassium.</li> <li>Hypertension artérielle.</li>
</ul>
<h2>Posologie et mode d'administration</h2>
<h3>Posologie</h3>
<p>Comme avec tous les autres diurétiques, le traitement doit être initié avec la plus petite dose possible. Cette dose doit être établie en fonction de la réponse individuelle du patient afin d’obtenir le bénéfice thérapeutique maximal tout en minimisant la survenue d’effets indésirables. La dose quotidienne d’ESIDREX peut être administrée en une ou deux prises, avec ou sans nourriture.</p>
<h5>Œdèmes</h5>
<p>La posologie initiale est de 50 à 100 mg/jour, éventuellement 200 mg/jour. La plus petite dose efficace doit être identifiée par titration et doit être administrée seulement sur des périodes limitées.</p>
<p>La posologie d’entretien est de 25 à 50 mg/jour ou 1 jour sur 2.</p>
<h5>Hypertension artérielle</h5>
<p>Les doses préconisées actuellement dans l’hypertension artérielle sont de 12,5 ou de 25 mg/jour.</p>
<p>Pour une posologie donnée, l’effet maximum est atteint en 3 à 4 semaines. Si la tension artérielle n’est pas suffisamment abaissée avec une dose de 25 mg /jour, un traitement combiné avec un autre médicament antihypertenseur est recommandé. La déplétion sodée et/ou volémique doit être corrigée avant d’utiliser ESIDREX en association avec un IEC, un antagoniste des récepteurs de l’angiotensine II ou un inhibiteur direct de la rénine. Dans le cas contraire, le traitement doit être initié sous surveillance médicale étroite.</p>
<h3>Populations particulières</h3>
<h5>Insuffisance rénale</h5>
<p>Aucun ajustement de la dose initiale n’est nécessaire chez les patients présentant une insuffisance rénale légère à modérée.</p>
<p>ESIDREX est contre-indiqué chez les sujets présentant une anurie et chez les patients atteints d’insuffisance rénale sévère.</p>
<h5>Insuffisance hépatique</h5>
<p>Aucun ajustement de la dose initiale n’est nécessaire chez les patients présentant une insuffisance hépatique légère à modérée.</p>
<p>ESIDREX doit être utilisé avec prudence chez les patients présentant une insuffisance hépatique sévère.</p>
<h3>Mode d’administration</h3>
<p>Ce médicament est à prendre par voie orale.</p>
<p>Avalez le(s) comprimé(s) sécable(s) avec un verre d’eau.</p>
<h2>Contre-indications</h2>
<p>Ce médicament ne doit jamais être utilisé en cas de&nbsp;:</p>
<ul>
  <li>hypersensibilité connue à l’hydrochlorothiazide ou à l’un des excipients,</li>
  <li>anurie,</li>
  <li>insuffisance rénale sévère (clairance de la créatinine inférieure à 30 ml/min).</li>
</ul>
<p>Ce médicament est contre-indiqué chez les patients présentant une allergie au blé (autre que la maladie cœliaque).</p>
<h2>Mises en garde spéciales et précautions d'emploi</h2>
<h3>Mises en garde spéciales</h3>
<p>Ce médicament est généralement déconseillé en cas de sténose bilatérale de l’artère rénale ou sur rein fonctionnellement unique et en cas d’hypokaliémie.</p>
<p>L’hydrochlorothiazide est un sulfamide. La possibilité d’une allergie croisée avec les autres sulfamides notamment antibactériens reste théorique et non validée en clinique.</p>
<h5>Insuffisance hépatique</h5>
<p>Les diurétiques thiazidiques, comme d’autres diurétiques peuvent induire un déséquilibre électrolytique, une encéphalopathie hépatique ou un syndrome hépato-rénal quand ils sont utilisés pour traiter les ascites cirrhotiques. L’hydrochlorothiazide doit être utilisé avec prudence particulièrement chez les sujets présentant des atteintes hépatiques sévères.</p>
<h5>Cancer de la peau non mélanome</h5>
<p>Un risque accru de cancer de la peau non mélanome (CPNM) [carcinome basocellulaire (CB) et carcinome épidermoïde (CE)] avec une augmentation de la dose cumulative d'exposition à l'hydrochlorothiazide (HCTZ) a été observé dans deux études épidémiologiques issues du registre danois des cancers. Les actions photosensibilisantes de l’HCTZ pourraient constituer un mécanisme possible du CPNM.</p>
<p>Les patients prenant de l’HCTZ doivent être informés du risque de CPNM et être invités à vérifier régulièrement leur peau pour détecter toute nouvelle lésion et à signaler rapidement toute lésion cutanée suspecte. Des mesures préventives possibles telles qu'une exposition limitée au soleil et aux rayons UV et, en cas d'exposition, une protection adéquate devraient être conseillées aux patients afin de minimiser le risque de cancer de la peau. Les lésions cutanées suspectes doivent être examinées rapidement, y compris éventuellement par un examen histologique des biopsies. L'utilisation d’HCTZ peut également devoir être reconsidérée chez les patients ayant déjà présenté un CPNM.</p>
<p>Des cas de réaction de photosensibilité ont été rapportés lors de l’utilisation des diurétiques thiazidiques.</p>
<p>En cas de survenue de réaction de photosensibilité sous traitement, il est recommandé d’interrompre le traitement. Si une réadministration du traitement est indispensable, il est recommandé de protéger les zones exposées au soleil ou aux UVA artificiels.</p>
<p>Ce médicament peut être administré en cas de maladie cœliaque. L’amidon de blé peut contenir du gluten, mais seulement à l’état de trace, et est donc considéré comme sans danger pour les sujets atteints d’une maladie cœliaque.</p>
<p>Ce médicament contient du lactose. Son utilisation est déconseillée chez les patients présentant une intolérance au galactose, un déficit en lactase de Lapp ou un syndrome de malabsorption du glucose ou du galactose (maladies héréditaires rares).</p>
<h3>Précautions d’emploi</h3>
<h4>Equilibre hydro-électrolytique</h4>
<h5>Natrémie</h5>
<p>La natrémie doit être contrôlée avant la mise en route du traitement, puis à intervalles réguliers par la suite.</p>
<p>Les diurétiques thiazidiques peuvent provoquer une hyponatrémie ou exacerber une hyponatrémie préexistante. Chez les sujets présentant une baisse importante de la natrémie et/ou une déplétion volémique importante, tel qu’observé chez les patients recevant de fortes doses de diurétiques, une hypotension symptomatique peut apparaître dans de rares cas après l’initiation du traitement par l’hydrochlorothiazide.</p>
<p>La baisse de la natrémie pouvant être initialement asymptomatique, un contrôle régulier est donc indispensable et doit être encore plus fréquent dans les populations à risque représentées par les sujets âgés, a fortiori dénutris, et les cirrhotiques.</p>
<p>Chez les patients ayant une ascite liée à une cirrhose hépatique, et chez les patients ayant un œdème lié à un syndrome néphrotique une surveillance particulière doit être effectuée.</p>
<p>Des cas isolés d’hyponatrémie accompagnée de symptômes neurologiques (nausées, désorientation progressive, apathie) ont été observés. Les diurétiques thiazidiques ne doivent être utilisés qu’après la normalisation de toute déplétion sodée et/ou volémique existante(s). Dans le cas contraire, le traitement sera instauré sous surveillance médicale étroite.</p>
<h5>Kaliémie</h5>
<p>Les diurétiques thiazidiques et apparentés peuvent provoquer la survenue d’une hypokaliémie ou exacerber une hypokaliémie préexistante. Les diurétiques thiazidiques doivent être administrés avec prudence chez les patients souffrant d’une pathologie pouvant être à l’origine d’une perte importante de potassium, comme par exemple dans les néphropathies avec perte de sels ou les troubles de la fonction rénale d’origine pré-rénale (cardiogéniques).</p>
<p>Le risque de survenue d’une hypokaliémie (&lt; 3,5 mmol/L) doit être prévenu dans certaines populations à risque représentées par les sujets âgés et/ou dénutris et/ou polymédiqués, les cirrhotiques avec œdèmes et ascite, les coronariens, les insuffisants cardiaques. En effet, dans ce cas, l’hypokaliémie majore la toxicité cardiaque des digitaliques et le risque de troubles du rythme.</p>
<p>Les patients présentant un espace QT long à l’ECG sont également à risque, que l’origine en soit congénitale ou médicamenteuse. L’hypokaliémie (de même que la bradycardie) agit alors comme un facteur favorisant la survenue de troubles du rythme sévères, en particulier des torsades de pointes, potentiellement fatales, surtout en présence d’une bradycardie.</p>
<p>La normalisation d’une hypokaliémie et de toute hypomagnésémie l’accompagnant est recommandée avant d’initier un traitement avec des diurétiques thiazidiques.</p>
<p>Le premier contrôle du potassium plasmatique doit être effectué au cours de la semaine qui suit la mise en route du traitement.</p>
<p>Par la suite, un contrôle régulier de la kaliémie est recommandé. L’équilibre électrolytique, particulièrement celui du potassium, doit être contrôlé chez tous les patients recevant des diurétiques thiazidiques.</p>
<p>En cas de traitement chronique, la kaliémie doit être contrôlée à l’initiation du traitement. Un contrôle à 3-4 semaines pourrait être envisagé en fonction des facteurs de risque. Ensuite un contrôle régulier doit être recommandé notamment chez les patients à risque.</p>
<h5>Acide urique</h5>
<p>ESIDREX, comme d’autres diurétiques, peut entrainer une augmentation des concentrations plasmatiques d’acide urique, du fait de la diminution de son élimination urinaire et par conséquent favoriser l’apparition d’une hyperuricémie ou aggraver une hyperuricémie préexistante pouvant déclencher des crises de goutte chez les patients prédisposés.</p>
<p>La posologie sera adaptée en fonction des concentrations plasmatiques d’acide urique.</p>
<h4>Effets métaboliques</h4>
<h5>Calcémie</h5>
<p>Les diurétiques thiazidiques diminuent l'excrétion urinaire du calcium et peuvent entraîner une élévation légère et transitoire de la calcémie en l’absence d’anomalies connues du métabolisme calcique. ESIDREX doit être utilisé avec prudence chez les patients présentant une hypercalcémie et il ne doit être administré qu’après correction de toute hypercalcémie préexistante. ESIDREX doit être interrompu en cas de survenue d’une hypercalcémie lors du traitement. La calcémie doit être contrôlée régulièrement pendant le traitement par les diurétiques thiazidiques. Une hypercalcémie marquée peut être le signe d’une hyperparathyroïdie masquée. Les diurétiques thiazidiques doivent être arrêtés avant d’explorer la fonction parathyroïdienne.</p>
<h5>Glycémie et lipidémie</h5>
<p>Les diurétiques thiazidiques, y compris l’hydrochlorothiazide, peuvent diminuer la tolérance au glucose et augmenter les taux sériques de cholestérol et triglycérides. Il peut être nécessaire d’adapter la posologie de l’insuline ou des hypoglycémiants oraux chez les patients diabétiques.</p>
<h5>Fonction rénale et diurétique</h5>
<p>Les diurétiques thiazidiques ne sont pleinement efficaces que lorsque la fonction rénale est normale ou peu altérée (évaluée par exemple par le calcul de la clairance de la créatinine à partir de la créatininémie). Chez le sujet âgé, la valeur de la clairance de la créatinine doit être réajustée en fonction de l’âge, du poids et du sexe du patient, selon la formule de Cockroft, par exemple&nbsp;:</p>
<p>Cl<sub>cr</sub> = (140 - âge) x poids/0,814 x créatininémie</p>
<p>Avec&nbsp;:</p>
<ul> <li>l’âge exprimé en années,</li> <li>le poids en kg,</li> <li>la créatininémie en micromol/L.</li>
</ul>
<p>Cette formule est valable pour les sujets âgés de sexe masculin, et doit être corrigée pour les femmes en multipliant le résultat par 0,85.</p>
<p>L’hypovolémie, secondaire à la perte d’eau et de sodium, induite par le diurétique en début de traitement, entraîne une réduction de la filtration glomérulaire. Il peut en résulter une augmentation de l’urée sanguine et de la créatininémie.</p>
<p>Cette insuffisance rénale fonctionnelle transitoire peut aggraver une insuffisance rénale préexistante.</p>
<h5>Myopie aiguë et glaucome secondaire à angle fermé</h5>
<p>L’hydrochlorothiazide, un sulfamide, a été associé à une réaction idiosynchrasique conduisant à une myopie transitoire aiguë et à un glaucome aigu à angle fermé. Les symptômes comprennent l’apparition brutale d’une diminution de l’acuité visuelle ou une douleur oculaire, et surviennent typiquement dans les quelques heures à semaines après l’initiation du traitement. En l’absence de traitement le glaucome à angle fermé peut entraîner une perte de vision permanente. Le traitement initial consiste à interrompre l’hydrochlorothiazide le plus rapidement possible. Des antécédents allergiques aux sulfamides ou à la pénicilline sont des facteurs de risque dans le développement d’un glaucome aigu à angle fermé.</p>
<h4>Autres</h4>
<h5>Associations anti-hypertensives</h5>
<p>Il est conseillé de réduire la posologie en cas d’association avec un autre antihypertenseur, au moins dans un premier temps.</p>
<p>L’effet anti-hypertenseur des IEC, des antagonistes de l’angiotensine II ou des inhibiteurs de la rénine est potentialisé par des traitements qui augmentent l’activité rénine plasmatique (diurétiques).</p>
<p>La prudence est recommandée lorsqu’un IEC, un antagoniste de l’angiotensine II ou un inhibiteur direct de la rénine est administré conjointement à ESIDREX, et plus particulièrement chez les patients présentant une déplétion sodée et/ou volémique importante.</p>
<h5>Sportifs</h5>
<p>L’attention des sportifs est attirée sur le fait que cette spécialité contient un principe actif pouvant induire une réaction positive des tests pratiqués lors des contrôles antidopages.</p>
<h5>Autre</h5>
<p>Lupus érythémateux&nbsp;: des cas d’exacerbation ou d’activation d’un lupus érythémateux disséminé ont été rapportés avec les diurétiques thiaziques, incluant l’hydrochlorothiazide.</p>
<p>Les réactions d’hypersensibilité à l’hydrocholorothiazide sont plus fréquentes chez les patients allergiques et asthmatiques.</p>
<h2>Interactions avec d'autres médicaments et autres formes d'interactions</h2>
<p>L’administration concomitante d’ESIDREX avec les médicaments suivants peut entraîner des interactions.</p>
<h4>+ Médicaments hypokaliémiants</h4>
<p>L’hypokaliéme est un facteur favorisant l’apparition de troubles du rythme cardiaque (torsades de pointes, notamment) et augmentant la toxicité de certains médicaments, par exemple la digoxine. De ce fait, les médicaments qui peuvent entraîner une hypokaliémie sont impliqués dans un grand nombre d’interactions. Il s’agit des diurétiques hypokaliémiants, seuls ou associés, des laxatifs stimulants, des glucocorticoïdes, du tétracosactide et de l’amphotéricine B (voie IV).</p>
<h4>+ Médicaments hyponatrémiants</h4>
<p>Certains médicaments sont plus fréquemment impliqués dans la survenue d’une hyponatrémie. Ce sont les diurétiques, la desmopressine, les antidépresseurs inhibant la recapture de la sérotonine,la carbamazépine et l’oxcarbazépine. L’association de ces médicaments majore le risque d’hyponatrémie.</p>
<h3>Associations déconseillées</h3>
<h4>+ Lithium</h4>
<p>Augmentation de la lithémie avec signes de surdosage en lithium, comme lors d’un régime désodé (diminution de l’excrétion rénale du lithium).</p>
<p>Si l’association ne peut être évitée, surveillance stricte de la lithémie et adaptation de la posologie du lithium.</p>
<h3>Associations faisant l'objet de précautions d’emploi</h3>
<h4>+ Acide acétylsalicylique</h4>
<p>Pour des doses anti-inflammatoires d’acide acétylsalicylique (≥ 1 g par prise et/ou ≥ 3 g par jour) ou pour des doses antalgiques ou antipyrétiques (≥ 500 mg par prise et/ou &lt; 3 g par jour)&nbsp;:</p>
<p>Insuffisance rénale aiguë chez le malade déshydraté, par diminution de la filtration glomérulaire secondaire à une diminution de la synthèse des prostaglandines rénales. Par ailleurs, réduction de l’effet antihypertenseur.</p>
<p>Hydrater le malade et surveiller la fonction rénale en début de traitement.</p>
<h4>+ Anti-inflammatoires non stéroïdiens</h4>
<p>Insuffisance rénale aiguë chez le malade à risque (sujet âgé et/ou déshydraté) par diminution de la filtration glomérulaire (inhibition des prostaglandines vasodilatatrices due aux anti-inflammatoires non stéroïdiens). Par ailleurs, réduction de l’effet antihypertenseur.</p>
<p>Hydrater le malade et surveiller la fonction rénale en début de traitement.</p>
<h4>+ Carbamazépine</h4>
<p>Risque d’hyponatrémie symptomatique. Surveillance clinique et biologique. Si possible, utiliser une autre classe de diurétiques.</p>
<h4>+ Résines chélatrices</h4>
<p>La prise de résine chélatrice peut diminuer l’absorption intestinale et, potentiellement, l’efficacité d’autres médicaments pris simultanément. D’une façon générale, la prise de la résine doit se faire à distance de celle des autres médicaments, en respectant un intervalle de plus de 2 heures, si possible.</p>
<h4>+ Digitaliques</h4>
<p>Hypokaliémie favorisant les effets toxiques des digitaliques.</p>
<p>Corriger auparavant toute hypokaliémie, et réaliser une surveillance clinique, électrolytique et électrocardiographique.</p>
<h4>+ Diurétiques épargneurs de potassium (seuls ou associés)</h4>
<p>L’association rationnelle, utile pour certains patients, n’exclut pas la survenue d’hypokaliémie ou, en particulier chez l’insuffisant rénal et le diabétique, d’hyperkaliémie.</p>
<p>Surveiller la kaliémie, éventuellement de l’ECG, et, s’il y a lieu, reconsidérer le traitement.</p>
<h4>+ Inhibiteurs de l'enzyme de conversion (IEC), antagoniste de l'angiotensine II</h4>
<p>Risque d’hypotension artérielle brutale et/ou d’insuffisance rénale aiguë lors de l’instauration ou de l’augmentation de la posologie d’un traitement par un inhibiteur de l’enzyme de conversion ou un antagoniste de l’angiotensine II en cas de déplétion hydrosodée préexistante.</p>
<p>Dans l’hypertension artérielle, lorsqu’un traitement diurétique préalable a pu entraîner une déplétion hydrosodée, il faut&nbsp;:</p>
<ul> <li>soit arrêter le diurétique avant de débuter le traitement par l’antagoniste de l’angiotensine II ou par l’IEC, et réintroduire un diurétique hypokaliémiant si nécessaire ultérieurement&nbsp;;</li> <li>soit administrer des doses initiales réduites d’antagoniste de l’angiotensine II ou d’IEC et augmenter progressivement la posologie.</li>
</ul>
<p>Dans l’insuffisance cardiaque congestive traitée par diurétiques, commencer par une dose très faible d’IEC ou d’antagoniste de l’angiotensine II, éventuellement après réduction de la dose du diurétique hypokaliémiant associé.</p>
<p>Dans tous les cas, surveiller la fonction rénale (créatininémie) dans les premières semaines du traitement par l’IEC ou par l’antagoniste de l’angiotensine II.</p>
<h4>+ Médicaments susceptibles de donner des torsades de pointes (amiodarone, amisulpride, arsénieux, arténimol, chloroquine, chlorpromazine, citalopram, cyamemazine, diphemanil, disopyramide, dofetilide, dolasetron, domperidone, dronedarone, droperidol, erythromycine, escitalopram, flupentixol, fluphenazine, halofantrine, haloperidol, hydroquinidine, hydroxyzine, ibutilide, levofloxacine levomepromazine, lumefantrine, mequitazine, methadone, mizolastine, moxifloxacine, pentamidine, pimozide, pipamperone, pipéraquine, pipotiazine, prucalopride, quinidine, sotalol, spiramycine, sulpiride, sultopride, tiapride, toremifene, vandétanib, vincamine, zuclopenthixol)</h4>
<p>Risque majoré de troubles du rythme ventriculaires, notamment de torsades de pointes.</p>
<p>Corriger toute hypokaliémie avant d’administrer le produit et réaliser une surveillance clinique, électrolytique et électrocardiographique.</p>
<h4>+ Autres hypokaliémiants</h4>
<p>Risque majoré d’hypokaliémie. Surveillance de la kaliémie avec si besoin correction.</p>
<h4>+ Produits de contraste iodés</h4>
<p>En cas de déshydratation provoquée par les diurétiques, risque majoré d’insuffisance rénale fonctionnelle aiguë, en particulier lors de l’utilisation de doses importantes de produits de contraste iodés.</p>
<p>Réhydratation avant administration du produit iodé.</p>
<h3>Associations à prendre en compte</h3>
<h4>+ Alphabloquants à visée urologique (alfuzosine, doxazosine, prazosine, silodosine, tamsulosine, térazosine)</h4>
<p>Majoration de l’effet hypotenseur. Risque d’hypotension orthostatique majoré.</p>
<h4>+ Anti-hypertenseurs alphabloquants</h4>
<p>Majoration de l’effet hypotenseur. Risque d’hypotension orthostatique majoré.</p>
<h4>+ Médicaments à l’origine d’une hypotension orthostatique</h4>
<p>Outre les antihypertenseurs, de nombreux médicaments peuvent entraîner une hypotension orthostatique. C’est le cas notamment des dérivés nitrés, des inhibiteurs de la phosphodiestérase de type 5, des alpha-bloquants à visée urologique, des antidépresseurs imipraminiques et des neuroleptiques phénothiaziniques, des agonistes dopaminergiques, de la lévodopa, du baclofène, de l’amifostine….</p>
<p>Risque de majoration d’une hypotension, notamment orthostatique.</p>
<h4>+ Calcium</h4>
<p>Risque d'hypercalcémie par diminution de l'élimination urinaire du calcium.</p>
<h4>+ Ciclosporine</h4>
<p>Risque d’augmentation de la créatininémie sans modification des concentrations sanguines de ciclosporine, même en l’absence de déplétion hydrosodée. Egalement risque d’hyperuricémie et de complications comme la goutte.</p>
<h4>+ Dérivés nitrés et apparentés</h4>
<p>Majoration du risque d’hypotension, notamment orthostatique.</p>
<h2>Fertilité, grossesse et allaitement</h2>
<h3>Grossesse</h3>
<p>Les données concernant l’utilisation de l’hydrochlorothiazide pendant la grossesse sont limitées spécialement pendant le premier trimestre. Les études chez les animaux sont insuffisantes.</p>
<p>L’hydrochlorothiazide traverse la barrière placentaire. Sur la base du mécanisme d’action pharmacologique de l’hydrochlorothiazide, son utilisation pendant le deuxième et le troisième trimestre de grossesse peut diminuer la perfusion fœto-placentaire et entraîner des effets fœtaux et néonataux tels qu’ictère, déséquilibres éléctrolytiques et thrombopénie.</p>
<p>L’hydrochlorothiazide ne doit pas être utilisé en cas d’œdème gestationnel, d’hypertension gestationnelle ou de pré-éclampsie en raison du risque de diminution du volume plasmatique et d’hypo perfusion placentaire, sans effet bénéfique sur l’évolution de la maladie.</p>
<p>L’hydrochlorothiazide ne doit pas être utilisé dans le traitement de l’hypertension essentielle chez les femmes enceintes, sauf dans de rares cas où aucun autre traitement ne peut être utilisé.</p>
<h3>Allaitement</h3>
<p>L’hydrochlorothiazide est excrété dans le lait maternel en faible quantité. Les diurétiques thiazidiques à fortes doses provoquant une diurèse importante peuvent inhiber la lactation.</p>
<p>L’utilisation d’ESIDREX pendant l’allaitement n’est pas recommandé. Si ESIDREX est utilisé pendant l’allaitement, les doses administrées doivent rester les plus faibles possible.</p>
<h3>Fertilité</h3>
<p>Il n’existe pas de données sur l’effet d’ESIDREX sur la fertilité chez l’homme.</p>
<p>Dans les études réalisées chez l’animal, l'hydrochlorothiazide n’a aucun effet sur la fertilité ni sur la conception.</p>
<h2>Effets sur l'aptitude à conduire des véhicules et à utiliser des machines</h2>
<p>Sans objet.</p>
<h2>Effets indésirables</h2>
<p>Les évènements indésirables (Tableau 1) sont classés par fréquence, en commençant par les plus fréquents selon la convention suivante&nbsp;: très fréquent (&gt; 1/10)&nbsp;; fréquent (&gt; 1/100, &lt;1/10)&nbsp;; peu fréquent (&gt; 1/1000, &lt;1/100)&nbsp;; rare (&gt; 1/10000, &lt;1/1000)&nbsp;; très rare (&lt;1/10000) et fréquence indéterminée (ne peut être estimée sur la base des données disponible).</p>
<table cellSpacing="0">
  <tbody>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections hématologiques et du système lymphatique</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Thrombocytopénie parfois avec purpura</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très rare</p>
      </td>
      <td width="304" valign="top">
        <p>Insuffisance médullaire, anémie hémolytique, leucopénie, agranulocytose</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Aplasie médullaire</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections du système immunitaire</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très rare</p>
      </td>
      <td width="304" valign="top">
        <p>Réaction d’hypersensibilité</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Troubles du métabolisme et de la nutrition</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Hypokaliémie, hyperlipidémie</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Hyperuricémie, hypomagnésémie, hyponatrémie</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Hypercalcémie, hyperglycémie, glycosurie, aggravation du diabète métabolique</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très rare</p>
      </td>
      <td width="304" valign="top">
        <p>Alcalose hypochlorémique</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections psychiatriques</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Dépression, troubles du sommeil</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections du système nerveux</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Céphalées, vertiges, paresthésies</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections oculaires</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Troubles visuels</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Glaucome aigu à angle fermé</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections cardiaques</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Arythmies</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections vasculaires</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Hypotension orthostatique</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections respiratoires, thoraciques et médiastinales</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très rare</p>
      </td>
      <td width="304" valign="top">
        <p>Détresse respiratoire (incluant pneumonie et œdème pulmonaire)</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections gastro-intestinales</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Diarrhée, perte d’appétit, nausées et vomissements</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Inconfort abdominal, constipation</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très rare</p>
      </td>
      <td width="304" valign="top">
        <p>Pancréatite</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections hépatobiliaires</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Cholestase intrahépatique, ictère</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections de la peau et du tissu sous-cutané</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Urticaire et autres formes d’éruptions cutanées</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Rare</p>
      </td>
      <td width="304" valign="top">
        <p>Réaction de photosensibilité</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Très rare</p>
      </td>
      <td width="304" valign="top">
        <p>Réaction de type lupus érythémateux, réactivation d’un lupus érythémateux, vascularite nécrosante et nécrolyse épidermique toxique</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Erythème polymorphe</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections musculo-squelettiques et systémiques</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Spasmes musculaires</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections du rein et des voies urinaires</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Peu fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Insuffisance rénale aiguë</p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Trouble de la fonction rénale</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Affections des organes de la reproduction et du sein</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquent</p>
      </td>
      <td width="304" valign="top">
        <p>Impuissance</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Troubles généraux et anomalies au site d’administration</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Asthénie, fièvre</p>
      </td>
    </tr>
    <tr>
      <td width="604" colSpan="2" valign="top">
        <p><strong>Tumeurs bénignes, malignes et non précisées (y compris kystes et polypes)</strong></p>
      </td>
    </tr>
    <tr>
      <td width="300" valign="top">
        <p>Fréquence indéterminée</p>
      </td>
      <td width="304" valign="top">
        <p>Cancer de la peau non mélanome* (carcinome basocellulaire et carcinome épidermoïde)</p>
      </td>
    </tr>
  </tbody>
</table>
<p><em>* D'après les données disponibles provenant d'études épidémiologiques, une association cumulative dose-dépendante entre l’HCTZ et le CPNM a été observée.</em></p>
<h3>Déclaration des effets indésirables suspectés</h3>
<p>La déclaration des effets indésirables suspectés après autorisation du médicament est importante. Elle permet une surveillance continue du rapport bénéfice/risque du médicament. Les professionnels de santé déclarent tout effet indésirable suspecté via le système national de déclaration&nbsp;: Agence nationale de sécurité du médicament et des produits de santé (ANSM) et réseau des Centres Régionaux de Pharmacovigilance - Site internet&nbsp;: <a href="http://www.ansm.sante.fr/" target="_blank" rel="noopener noreferrer">www.ansm.sante.fr</a>.</p>
<h2>Surdosage</h2>
<p>Un surdosage avec l’hydrochlorothiazide est associé à une déplétion électrolytique (hypokaliémie, hypochlorémie, hyponatrémie) et à une déshydratation due à une diurèse excessive. Les signes et symptômes les plus fréquents d’un surdosage sont des nausées et une somnolence. L’hypokaliémie peut entraîner des spasmes musculaires et/ou accentuer les arythmies cardiaques associées à l’utilisation concomitante de glucosides digitaliques ou de certains médicaments antiarythmiques.</p>
<p>Le traitement consiste à restaurer l’équilibre hydro-électrolytique, la correction de l’hyponatrémie doit être progressive. Une surveillance cardiovasculaire doit être mise en œuvre selon l’état clinique.</p></React.Fragment>