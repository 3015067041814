import React, { useContext, useEffect } from 'react'
import styled from 'styled-components';
import { Redirect } from "react-router-dom";

import { HTAContext } from '../App';
import VideoControls from './layout/VideoControls';
import Choice from './Choice';

const Game = (props) => {
  const { state } = useContext(HTAContext);
  const { step } = props.match.params;
  const { choices } = state;

  useEffect(() => {
    document.getElementsByClassName('App')[0].classList.add('game');
    return () => {
      document.getElementsByClassName('App')[0].classList.remove('game');
    };
  }, []);
  
  return (
    <Wrapper id='game'>
      <div className='inner-wrapper'>
        { !!step && !!choices[step]
          ? <Choice step={step} />
          : <Redirect to='/' /> }
        <VideoControls />
      </div>
    </Wrapper>
  )
}

export default React.memo(Game);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;