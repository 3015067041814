import React from 'react'
import styled from 'styled-components';

import bgLoading from '../img/background-loading.jpg';
import { ReactComponent as HTA } from '../svg/hta-logo.svg';
import { Colors } from '../Constants';

const Loading = () => {
  return (
    <Wrapper className='wrapper-absolute'>
      <HTALogo/>
      <CircleBack />
      <CircleFront>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </CircleFront>
    </Wrapper>
  )
}

export default Loading

const Wrapper = styled.div`
  background-image: url(${bgLoading});
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100vh;

  & .lds-ring {
    display: inline-block;
    position: relative;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
  & .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid ${Colors.RED};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${Colors.RED} transparent transparent transparent;
  }
  & .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;

const HTALogo = styled(HTA)`
  width: calc(30vmin);
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 30%;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    top: 20%;
    width: calc(45vmin);
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: calc(45vmin);
  }
`;

const CircleBack = styled.span`
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${Colors.RED};
  width: calc(12vmin);
  height: calc(12vmin);
  position: absolute;
  left: 50%;
  bottom: 25%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 768px) and (orientation: portrait){
    top: 60%;
    width: calc(20vmin);
    height: calc(20vmin);
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    top: 65%;
    width: calc(17vmin);
    height: calc(17vmin);
  }
`;

const CircleFront = styled(CircleBack)`
  border: none;
`