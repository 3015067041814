import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { ReactComponent as PlaySVG } from '../svg/play.svg';
import { vmin, vw } from '../utils';
import { Colors } from '../Constants';

const PlayButton = props => {
  const radius = (vmin(vw(100) > 768 ? 7 : 10) + 30) / 2;
  const circumference = radius * 2 * Math.PI;

  return (
    <Wrapper {...props}>
      <div className='semi-circle' />
      <svg className='circle' height='100%' width='100%'>
        <circle
          strokeWidth={2} strokeDasharray={`${circumference} ${circumference}`} strokeDashoffset={0}
          stroke={Colors.RED_BRIGHT} fill='transparent' r='50%' cx='50%' cy='50%'
        />
      </svg>
      <PlaySVG className='play' />
      { props.children }
    </Wrapper>
  )
}

export default React.memo(PlayButton);
const Wrapper = styled(animated.div)`
  border-radius: 50%;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};
  width: calc(30px + 7vmin);
  height: calc(30px + 7vmin);
  position: relative;
  cursor: pointer;
  background-image: radial-gradient(ellipse farthest-corner at 5px 5px, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.05) );

  & svg.circle {
    pointer-events: none;
    overflow: visible;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-in-out;
  }

  & svg.circle circle {
    transition: stroke-dashoffset 0.15s ease-in-out;
  }

  & svg.play {
    pointer-events: none;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 5px), calc(-50% + 2px));
  }

  & > .semi-circle {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translate(-25%,-50%) rotate(90deg);
    width: 120%;
    height: 60%;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    border: 2px solid ${Colors.WHITE};
    border-bottom: 0;
  }
  
  & .start-bt-title {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(calc(-100% - 10px - 1.5vmin), -50%);
    text-transform: uppercase;
    text-align: right;
    font-weight: bold;
    transition: transform 0.15s ease-in-out;
  }

  @media screen and (min-width: 769px) {
    :hover {
      background-image: none;
      box-shadow: none;
    }

    &:hover .start-bt-title {
      transform: translate(-75%, -50%);
    }

    &:hover .info {
      opacity: 1;
      transform: translate(-50%, 100%) scale(1);
    }

    &:hover svg.circle {
      transform: rotate(230deg);
    }

    &:hover svg.circle circle {
      stroke-dashoffset: 85;
    }
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    width: calc(30px + 10vmin);
    height: calc(30px + 10vmin);
    margin-top: calc(15px + 2vmin);
  }
`;