import React, { useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

import { Colors, REF_NUMBER, FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE_LANDSCAPE } from '../../Constants';
import { HTAContext } from '../../App';
import { TOGGLE_POPUP } from '../../context/hta-context';
import Sound from '../../Sound';

const Footer = () => {
  const { t } = useTranslation();

  const { state, dispatch } = useContext(HTAContext);
  const togglePopup = useCallback(name => {
    dispatch({ type: TOGGLE_POPUP, payload: name });
  }, [dispatch])

  const footerAnim = useSpring({ from: { transform: 'translateY(100%)', opacity: 0 }, to: { transform: 'translateY(0)', opacity: 1 } });

  return (
    <Wrapper className='App-footer' style={Object.assign({ backgroundColor: Colors.TRANSPARENT_BLUE, userSelect: 'none' }, footerAnim)} >
      <span style={{ opacity: 0.5 }} >{REF_NUMBER}</span>
      <ButtonsWrapper>
        <Button isOn={state.popup === 'references'} onClick={e => { Sound.Click(); togglePopup('references'); e.currentTarget.blur();}}>{ t('Références') }</Button>
        <Button isOn={state.popup === 'legal'} onClick={e => { Sound.Click(); togglePopup('legal'); e.currentTarget.blur();}}>{ t('Mentions Légales') }</Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default Footer;

const Wrapper = styled(animated.footer)`
  height: ${`${FOOTER_HEIGHT}px`};
  box-shadow: 3px -4px 4px 0px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 900px) and (orientation: landscape) {
    height: ${`${FOOTER_HEIGHT_MOBILE_LANDSCAPE}px`};
    box-shadow: none;
  }
  @media screen and (max-width: 768px) and (orientation: portrait) {
    box-shadow: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Button = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  border: 1px solid ${Colors.TRANSPARENT_WHITE};
  text-align: center;
  padding: 0 2rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.1s linear;

  &:not(:last-child) {
    border-right: none;
  }

  :active {
    text-shadow: none;
    background-color: ${Colors.TRANSPARENT_BLUE};
  }

  ${props => props.isOn && css`
    font-weight: bold;
    text-shadow: 0px 0px 10px #CECECE;
    background-color: ${Colors.BLUE};
    box-shadow: inset #ffffff30 0 0 5px 2px;
  `}

  @media screen and (min-width: 769px){
    :hover,
    :focus {
      font-weight: bold;
      text-shadow: 0px 0px 10px #CECECE;
      background-color: ${Colors.BLUE};
    }
  }

  @media screen and (max-width: 768px) and (orientation: portrait){
    border-top: none;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    padding: 0 1rem;
  }
`;
