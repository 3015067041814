import React, { useContext, useCallback, useRef } from 'react'
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSpring, animated, useChain } from 'react-spring';
import { isMobile } from 'react-device-detect';
import useDeviceOrientation from '../../hooks/useDeviceOrientation';

import { Colors, FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE_LANDSCAPE } from '../../Constants';
import { ReactComponent as Corner } from '../../svg/corner.svg';
import { ReactComponent as Volume } from '../../svg/volume.svg';
import { ReactComponent as Fullscreen } from '../../svg/fullscreen.svg';
import { ReactComponent as Captions } from '../../svg/captions.svg';
import { HTAContext } from '../../App';
import { setFullscreen, setMute, setCaptions } from '../../context/hta-context';
import Sound from '../../Sound';

const VideoControls = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(HTAContext);
  const { fullscreen, mute, captions } = state;
  const orientation = useDeviceOrientation();

  const toggleFullscreen = useCallback(() => {
    Sound.Click();
    setFullscreen(!fullscreen)(dispatch)
  }, [dispatch, fullscreen]);
  const toggleMute = useCallback(() => {
    Sound.Click();
    setMute(!mute)(dispatch)
  }, [dispatch, mute]);
  const toggleCaptions = useCallback(() => {
    Sound.Click();
    setCaptions(!captions)(dispatch)
  }, [dispatch, captions]);
  
  const animRef = useRef(), animInfoRef = useRef();

  const anim = useSpring({ from: (isMobile && orientation === 'portrait') ? { opacity: 0 } : { transform: 'translateX(100%)' }, to: (isMobile && orientation === 'portrait') ? { opacity: 1 } : { transform: 'translateX(0)' }, ref: animRef });
  const animInfo = useSpring({
    from: { opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      setTimeout(async () => {
        await next({ opacity: 0 })
      }, 2000);
    },
    config: { duration: 1000 }, ref: animInfoRef });

  useChain([animRef, animInfoRef], [0, 1]);

  return (
    <Wrapper style={anim}>
      <InnerWrapper className='inner-wrapper'>
        <RedCorner className='no-mobile-portrait' />
        <CaptionsBT title={ t('Sous-titres') } className={classnames('svg-bt', { 'on': captions })} onClick={toggleCaptions} />
        <Volume title={ mute ? t('Activer le son') : t('Désactiver le son') } className={classnames('svg-bt', { 'on': !mute })} onClick={toggleMute} />
        <Fullscreen title={ fullscreen ? t('Plein écran') : t('Quitter le plein écran') } className={classnames('svg-bt', { 'on': fullscreen })} onClick={toggleFullscreen} />
        { !fullscreen && <Info style={animInfo} className='info no-mobile'>
          <Arrow viewBox='0 0 100 50'>
            <path d='M 0,50 L 50,0 L 100,50' />
          </Arrow>
          <Border />
          <Border top={true} />
          <span>{ t('Pour une meilleure expérience, activez le mode plein écran') }</span>
          <RedCorner className='red-corner' />
        </Info>}
      </InnerWrapper>
    </Wrapper>
  )
}

export default VideoControls;

const Wrapper = styled(animated.div)`
  background-color: ${Colors.TRANSPARENT_BLUE};
  position: absolute;
  bottom: ${`${FOOTER_HEIGHT}px`};
  right: 0;
  border: 1px solid ${Colors.TRANSPARENT_WHITE};
  border-bottom: none;
  box-shadow: -4px -3px 4px 0px rgba(0, 0, 0, 0.1);

  & .svg-bt {
    width: 30px;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
  }

  & .svg-bt:not(:first-of-type) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & .svg-bt:nth-child(2) {
    margin-top: 25px;
  }

  & .svg-bt:last-of-type {
    margin-bottom: 25px;
  }

  & .svg-bt:focus circle,
  & .svg-bt:hover circle {
    fill: ${Colors.TRANSPARENT_RED};
  }

  & .svg-bt.on * {
    stroke: ${Colors.RED};
  }

  & .svg-bt.on circle {
    fill: ${Colors.RED};
  }

  @media only screen and (min-width: 769px) {
    & .svg-bt:focus *,
    & .svg-bt:hover * {
      stroke: ${Colors.TRANSPARENT_RED};
    }
  }
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    left: 0;
    border-bottom: 1px solid ${Colors.TRANSPARENT_WHITE};
    box-shadow: none;

    & .svg-bt:not(:first-of-type) {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 30px;
    }
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    bottom: ${`${FOOTER_HEIGHT_MOBILE_LANDSCAPE}px`};
    box-shadow: none;

    & .svg-bt {
      margin: 7px;
      width: 20px;
      height: 20px;
    }

    & .svg-bt:not(:first-of-type) {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 20px;
      height: 20px;
    }
  }
`;
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 768px) and (orientation: portrait) {
    flex-direction: row;
  }
`;
const RedCorner = styled(Corner)`
  position: absolute;
  top: -2px;
  left: -1px;
  width: 1rem;
  transform: rotate(90deg);
`;

const CaptionsBT = styled(Captions)`
  @media only screen and (min-width: 769px) {
    .inner-wrapper:focus > & .captions-cls-3,
    .inner-wrapper:hover > & .captions-cls-3 {
      fill: ${Colors.TRANSPARENT_RED};
    } 
  }
  &.on .captions-cls-3 {
    fill: ${Colors.RED};
  } 
`;

const Info = styled(animated.div)`
  pointer-events: none;
  border: solid 1px ${Colors.TRANSPARENT_WHITE};
  border-right: none;
  width: calc(50px + 20vmin);
  height: calc(20px + 10vmin);
  position: absolute;
  bottom: 6px;
  right: 70px;
  transform-origin: bottom right;
  background-color: ${Colors.BLUE}44;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.15s ease-in;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};
  z-index: 2;

  & > svg.volume {
    height: calc(8px + 1vmin);
    margin-bottom: 2vmin;
  } 

  & > span {
    width: 70%;
  }

  & > .red-corner {
    width: 1rem;
    height: 1rem;
  }
`;

const Border = styled.div`
  border-right: 1px solid ${Colors.TRANSPARENT_WHITE};
  position: absolute;
  top: ${props => props.top ? 0 : 'auto'};
  bottom: ${props => props.top ? 'auto' : 0};
  right: 0;
  height: ${props => props.top ? 60 : 20}%;
`;
const Arrow = styled.svg`
  width: 10%;
  position: absolute;
  right: 0;
  top: 60%;
  transform-origin: center bottom;
  transform: translateX(50%) rotate(90deg);
  margin: 0;
  overflow: visible;

  & > path {
    stroke-width: 3px;
    stroke: ${Colors.WHITE};
    fill: ${Colors.BLUE}44;
  }
`;