import React, { useCallback, useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import { parse as parseVTT } from 'subtitle';
import canAutoPlay from 'can-autoplay';

import Captions from './Captions';
import { HTAContext } from '../App';
import useDeviceOrientation from '../hooks/useDeviceOrientation';
import PlayButton from './PlayButton';

const Video = props => {
  const { state } = useContext(HTAContext);
  const { mute, captions: showCaptions, choices, knowsRules } = state;
  const { playing, videoData, onVideoProgress, onEnded, step } = props;
  const orientation = useDeviceOrientation();

  // Ref
  const player = useRef();
  const shadow = useRef();
  
  const [canPlay, setCanPlay] = useState(true);
  // Video time progress
  const [videoTime, setVideoTime] = useState(0);
  const setVideoProgress = useCallback(e => {
    setVideoTime(e.playedSeconds);
    if (typeof onVideoProgress === 'function') {
      onVideoProgress(e);
    }
  }, [onVideoProgress]);
  
  // Subtitle tracks
  const { vtt, vttURL } = videoData;
  const [subData, setSubData] = useState([]);
  const [subtitleTrack, setSubtitleTrack] = useState(null);

  const onPlayerReady = useCallback(e => {
    setSubtitleTrack(player.current.getInternalPlayer().textTracks[0])
  }, []);
  const onEnd = useCallback(
    e => {
      const videoPlayer = player.current.getInternalPlayer();
      const canvas = document.createElement('canvas');
      canvas.width = videoPlayer.videoWidth;
      canvas.height = videoPlayer.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
      const dataURI = canvas.toDataURL('image/jpeg');
      videoPlayer.parentNode.style.backgroundImage = `url(${dataURI})`;
      setVideoTime(0);
      onEnded(e);
    },
    [onEnded],
  )
  const playVideo = useCallback(() => setCanPlay(true), []);
  const onError = useCallback(
    (e) => {
      canAutoPlay.video()
        .then(({ result, error }) => {
          if (result === false || error) {
            setCanPlay(false);
          }
        })
        .catch(() => setCanPlay(false));
    },
    [],
  )

  useEffect(() => {
    setVideoTime(0);
    setSubData(parseVTT(vtt))
  }, [vtt]);
  useEffect(() => {
    if (isMobile && subtitleTrack) {
      subtitleTrack.mode = (showCaptions && orientation === 'portrait') ? 'showing' : 'disabled';
    }
  }, [vttURL, subtitleTrack, orientation, showCaptions]);
  useEffect(() => {
    if (isMobile) {
      player.current.seekTo(videoTime);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation]);

  let videoURL = choices[step].video;
  if (videoURL.includes('/')) {
    videoURL = videoURL.split('/')[knowsRules ? 0 : 1];
  }
  const res = isMobile ? (orientation === 'portrait' ? '480' : '1080') : '1080';
  const url = `${process.env.PUBLIC_URL}/videos/${res}/${videoURL}.mp4`;
  
  return (
    <React.Fragment>
      <VideoContainer ref={player} className='wrapper-absolute' url={url} width="auto" height="auto"
        onReady={onPlayerReady} onError={onError}
        playsinline={true}
        config={{ file: { tracks: [{ kind: 'captions', src: vttURL, srcLang: 'fr', default: isMobile && orientation === 'portrait' && showCaptions }] }}}
        playing={playing && canPlay} muted={mute} onProgress={setVideoProgress} onEnded={onEnd} />
      <Wrapper>
        { !canPlay && <PlayButton style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', margin: 0 }} onClick={playVideo} /> }
      </Wrapper>
      <Shadow ref={shadow} />
      { (!isMobile || orientation === 'landscape') && !!vtt && subData.length > 0 && showCaptions && playing && <Captions subData={subData} time={videoTime * 1000} /> }
    </React.Fragment>
  )
}
export default React.memo(Video);

const VideoContainer = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;
  pointer-events: none;
  background-size: cover;
  background-position: center;
  
  & > video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%;

    @media (min-aspect-ratio: 16/9) {
      max-width: 100%;
    }

    @media (max-aspect-ratio: 16/9) {
      max-height: 100%;
    }
    
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  
  @media screen and (max-width: 768px) and (orientation: portrait) {
    position: initial;
    height: calc(100vw * 9 / 16) !important;
    
    & > video {
      position: initial;
      top: auto;
      left: auto;
      transform: none;
      min-width: auto; 
      min-height: auto;
      min-width: auto; 
      min-height: auto;
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const Shadow = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  opacity: 0.85;
  
  @media screen and (max-width: 768px) and (orientation: portrait) {
    height: calc(100vw * 9 / 16) !important;
    opacity: 0.5;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    height: calc(100vw * 9 / 16) !important;
    opacity: 0.5;
  }
`;