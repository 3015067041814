export const SET_FULLSCREEN = 'SET_FULLSCREEN';
export const SET_MUTE = 'SET_MUTE';
export const SET_CAPTIONS = 'SET_CAPTIONS';
export const SET_CAN_PLAY = 'SET_CAN_PLAY';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const KNOWS_RULES = 'KNOWS_RULES';


const reducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: payload
      }
    case SET_MUTE:
      return {
        ...state,
        mute: payload
      }
    case SET_CAPTIONS:
      return {
        ...state,
        captions: payload
      }
    case SET_CAN_PLAY:
      return {
        ...state,
        canPlay: true
      }
    case TOGGLE_POPUP:
      return {
        ...state,
        popup: state.popup === payload ? null : payload
      }
    case CLOSE_POPUP:
      return {
        ...state,
        popup: null
      }
    case KNOWS_RULES:
      return {
        ...state,
        knowsRules: true
      }
  
    default:
      return state;
  }
}

export default reducer;

export const setFullscreen = value => dispatch => {
  dispatch({
    type: SET_FULLSCREEN,
    payload: value
  })
}
export const setMute = value => dispatch => {
  dispatch({
    type: SET_MUTE,
    payload: !!value
  })
}
export const setCaptions = value => dispatch => {
  dispatch({
    type: SET_CAPTIONS,
    payload: !!value
  })
}
