import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

import { Colors, SLIDER_DEFAULT_VALUE, SLIDER_DEFAULT_STEP, SLIDER_DEFAULT_MAX, SLIDER_DEFAULT_MIN } from '../Constants';
import { ReactComponent as Minus } from '../svg/minus.svg';
import { ReactComponent as Plus } from '../svg/plus.svg';
import { max, min } from '../utils';
import Sound from '../Sound';

const EsidrexSlider = props => {
  const { t } = useTranslation('game');
  const { correctValue, onWin, showCorrect } = props;
  const [value, setValue] = useState(SLIDER_DEFAULT_VALUE);

  const slider = useRef();
  const timeout = useRef();

  const incrementValue = useCallback(() => {
    Sound.Slider();
    setValue(value => min(value + SLIDER_DEFAULT_STEP, SLIDER_DEFAULT_MAX));
  }, []);
  const decrementValue = useCallback(() => {
    Sound.Slider();
    setValue(value => max(value - SLIDER_DEFAULT_STEP, SLIDER_DEFAULT_MIN));
  }, []);

  const onSliderChange = useCallback(newValue => {
    if (showCorrect) {
      return;
    }
    Sound.Slider();
    setValue(newValue);

    if (newValue === correctValue) {
      timeout.current = setTimeout(() => {
        Sound.SliderValid();
        document.getElementById('thumb-bg').classList.add('correct');
        onWin();
      }, 2000);
    } else {
      clearTimeout(timeout.current);
    }
  }, [correctValue, onWin, showCorrect]);

  const onBodyKeyDown = useCallback(
    (e) => {
      if (slider.current.sliderRef === e.target.parentNode) {
        return;
      }
      if (e.key === 'ArrowLeft') {
        decrementValue();
        slider.current.focus();
      } else if (e.key === 'ArrowRight') {
        incrementValue();
        slider.current.focus();
      }
    },
    [decrementValue, incrementValue, slider],
  )
  useEffect(() => {
    document.body.addEventListener('keydown', onBodyKeyDown, false);
    return () => {
      document.body.removeEventListener('keydown', onBodyKeyDown, false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (showCorrect) {
      const diff = Math.abs(correctValue - value);
      setTimeout(() => {
        if (value > correctValue) {
          decrementValue();
        } else if (value < correctValue) {
          incrementValue();
        } else {
          document.getElementById('thumb-bg').classList.add('correct');
          onWin();
        }
      }, 1 / diff * 100);
    }
  }, [value, correctValue, showCorrect, onWin, decrementValue, incrementValue]);
  const animation = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }});

  return (
    <Wrapper style={animation} className={classnames({'no-pointer': showCorrect})}>
      <SliderBT left={true} onClick={decrementValue}><Minus width='1vw' /></SliderBT>
      <Slider ref={slider} min={SLIDER_DEFAULT_MIN} max={SLIDER_DEFAULT_MAX} dots={true} step={SLIDER_DEFAULT_STEP} value={value}
        style={{ zIndex: 2 }}
        onChange={onSliderChange}
        railStyle={{ display: 'none' }}
        dotStyle={{ border: 'none', backgroundColor: Colors.RED, boxShadow: '#000000aa 1px 1px 5px 1px', width: '0.325vw', height: '0.325vw', minWidth: 3, minHeight: 3 }}
        activeDotStyle={{ border: 'none' }}
        handle={props => {
          const { value, dragging, index, ...restProps } = props;
          return <Slider.Handle {...restProps}><Thumb {...props} className=''><ThumbBG id='thumb-bg' /><ThumbText className='no-mobile-portrait' value={value}>{`${props.value} ${t('mg/jour') }`}</ThumbText></Thumb></Slider.Handle>
          }
        }
      />
      <SliderBT left={false} onClick={incrementValue}><Plus width='1vw' /></SliderBT>
      <ThumbText className='mobile-only-portrait' value={value}>{`${value} ${t('mg/jour') }`}</ThumbText>
    </Wrapper>
  )
}

export default React.memo(EsidrexSlider);
const Wrapper = styled(animated.div)`
  position: relative;
  height: 50px;
  user-select: none;

  & .rc-slider-track {
    display: none;
  }

  & .rc-slider-dot:first-child::before,
  & .rc-slider-dot:last-child::before {
    content: '';
    position: absolute;
    width: 450%;
    height: 450%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border: 1px solid ${Colors.RED};
    border-radius: 50%;
    pointer-events: none;
  }

  & .rc-slider-dot:nth-child(2),
  & .rc-slider-dot:nth-last-child(2) {
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .rc-slider {
      width: 70%
    }
  }

  &.no-pointer,
  &.no-pointer .rc-slider-track,
  &.no-pointer .rc-slider-dot,
  &.no-pointer .rc-slider-handle {
    pointer-events: none !important;
  }
`;

const Thumb = styled.div`
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
  position: absolute;
  left: ${props => props.offset}%;
  white-space: nowrap;
  overflow: visible;
  text-align: center;
  cursor: grab;

  :active {
    cursor: grabbing;
  }
`;

const ThumbBG = styled.div`
  width: 40px;
  height: 40px;

  background: rgb(220,96,56); /* Old browsers */
  background: -moz-linear-gradient(-45deg,  rgba(220,96,56,1) 0%, rgba(226,6,19,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  rgba(220,96,56,1) 0%,rgba(226,6,19,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  rgba(220,96,56,1) 0%,rgba(226,6,19,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dc6038', endColorstr='#e20613',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  border-radius: 4px;
  box-shadow: #000000BB 0px 0px 7px 2px;
  transform-origin: center;
  transform: rotate(45deg) translate(4px, 4px);
  transition: background 0.2s ease-in;

  &.correct {
    background: rgb(22, 169, 26); /* Old browsers */
    background: -moz-linear-gradient(-45deg,  rgb(22, 169, 26) 0%, rgb(14, 220, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgb(22, 169, 26) 0%,rgb(14, 220, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgb(22, 169, 26) 0%,rgb(14, 220, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#16a91a', endColorstr='#0edc00',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  :active {
    box-shadow: inset #000000BB 0px 0px 7px 2px;
  }
`;

const ThumbText = styled.span`
  text-align: center;
  transform: translate(-50%, 100%);
  position: absolute;
  bottom: -0.75rem;
  font-weight: bold;
  font-size: calc(14px + 1vmin);
  text-shadow: #000 1px 1px 4px;

  ${props => (props.value > 30 || props.value < 4) && css`
    transform: translate(-50%, -100%);
    bottom: auto;
    top: -0.75rem;
  `}
  
  @media screen and (max-width: 768px) and (orientation: portrait) {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    top: auto;
  }
`;
const SliderBT = styled.span`
  position: absolute;
  top: 20px;
  width: 1vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  
  ${props => props.left
    ? css`
      left: 0;
      transform: translateX(-50%);
      `
    : css`
      right: 0;
      transform: translateX(50%);
    `
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    position: initial;
    width: 15%;
    transform: none;
    flex-shrink: 0;
    height: 100%;

    ${props => props.left && css`
      margin-right: 2%;
    `}
    
    & > svg {
      width: 40%;
      height: 40%;
    }
  }
`;