import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import Popup from './layout/Popup';
import { Icons, FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE_LANDSCAPE } from '../Constants';
import { HTAContext } from '../App';
import { CLOSE_POPUP } from '../context/hta-context';

const PopupOverlay = () => {
  const { state, dispatch } = useContext(HTAContext);

  const { popup: id } = state;
  const onClose = useCallback(() => dispatch({ type: CLOSE_POPUP }), [dispatch]);

  return (
    <Wrapper className='wrapper-absolute'>
      <Popup onClose={onClose} icon={Icons[id]} fullOverlay={true} whiteStyle={true} id={id} />
    </Wrapper>
  )
}

export default React.memo(PopupOverlay);
const Wrapper = styled.div`
  z-index: 10;
  position: fixed;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    #game ~ & {
      top: calc(100vw * 9 / 16);
    }
    bottom: ${`${FOOTER_HEIGHT}px`};
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    bottom: ${`${FOOTER_HEIGHT_MOBILE_LANDSCAPE}px`};
  }
`;
