import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import ScrollArea from 'react-scrollbar';
import classnames from 'classnames';
import useEventListener from '@use-it/event-listener';

import i18n from '../../i18n';
import { Colors, FOOTER_HEIGHT, SCROLLBAR_CONTAINER_STYLE, SCROLLBAR_STYLE, Icons, FOOTER_HEIGHT_MOBILE_LANDSCAPE } from '../../Constants';
import { ReactComponent as Cross } from '../../svg/cross.svg';
import RedCorner from './RedCorner';
import ArrowCorner from './ArrowCorner';
import Sound from '../../Sound';

const Popup = props => {
  const { t } = useTranslation('game');
  const { id, onClose, fullOverlay, whiteStyle, icon: CustomIcon } = props;

  const popupRef = useRef();
  const closeBT = useRef();
  const [HTMLContent, setHTMLContent] = useState(null);

  const closePopup = useCallback(
    () => {
      popupRef.current.focus();
      closeBT.current.style.display = 'none';
      popupRef.current.style.transform = 'scale(0)';
      setTimeout(onClose, 150);
    },
    [onClose],
  )
  const onCloseBTClick = useCallback(
    () => {
      Sound.Click();
      closePopup();
    },
    [closePopup],
  )

  useEffect(() => {
    const popup = popupRef.current;
    popup.style.transform = 'scale(1)';
    closeBT.current.style.display = 'flex';
    closeBT.current.style.opacity = 1;
    popup.focus();
    Sound.PopupOpen();
    popup.querySelector('.scrollarea').addEventListener('touchmove', onScrollTouch, { passive:false });
    return () => {
      // popup.querySelector('.scrollarea').removeEventListener('touchmove', onScrollTouch, { passive:false });
    }
  });
  useEffect(() => {
    if (id) {
      setHTMLContent(i18n.getResource(i18n.language, 'html', id));
    } else {
      setHTMLContent(null);
    }
  }, [id]);
  
  useEventListener('keydown', (e => fullOverlay && e.key === 'Escape' && closePopup()), document.body);
  const CustomTitle = t(`popup-${id}-title`) !== `popup-${id}-title` ? t(`popup-${id}-title`).split('\n') : null;
  const onScrollTouch = useCallback(e => e.preventDefault(), [])
  
  return (
    <Wrapper ref={popupRef} className={classnames({ 'full-overlay': fullOverlay, white: whiteStyle })}>
      <InnerWrapper>
        <Content>
          <Icon className="no-mobile-landscape">{(CustomIcon && <CustomIcon />) || <Icons.info />}</Icon>
          {!!CustomTitle && CustomTitle.map((titleSegment, i) => i === 0 ? <h2 key='popup-title'>{titleSegment}</h2>: <h3 key={`popup-title-${i}`}>{titleSegment}</h3>)}
          <ScrollArea key={id} horizontal={false} smoothScrolling={true} verticalContainerStyle={SCROLLBAR_CONTAINER_STYLE} verticalScrollbarStyle={SCROLLBAR_STYLE} >
            <Text>
              { !!HTMLContent
                ? HTMLContent
                : t(`popup-${id}`).split('\n').map((line, i) => <p key={`line-${i}`}>{line}</p>)
              }
            </Text>
          </ScrollArea>
        </Content>
        <BorderTop className="no-mobile-portrait" />
        <BorderLeft className="no-mobile-portrait" />
        <Corner className="no-mobile-portrait" left={true} nomargin={true} />
      </InnerWrapper>
      <ArrowCornerRed />
      <CloseBT className="no-mobile-portrait" ref={closeBT} onClick={onCloseBTClick}><CloseBTCross /></CloseBT>
    </Wrapper>
  )
}
Popup.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  fullOverlay: PropTypes.bool,
  whiteStyle: PropTypes.bool,
  icon: PropTypes.object,
}
export default React.memo(Popup);
const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  max-width: 25%;
  top: 15%;
  bottom: ${`${250 + FOOTER_HEIGHT}px`};

  & h2,
  & h3 {
    margin-top: 0;
  }

  transform: scale(0);
  transform-origin: -4rem 0;
  transition: transform 0.2s ease-in-out;

  &.full-overlay {
    top: 12%;
    bottom: 0;
    max-width: 85%;
    margin-bottom: -2px;
  }

  @media screen and (max-width: 768px) and (orientation: portrait){
    transform-origin: initial;
    transform: none;
    transition: none;
    
    &,
    &.full-overlay {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    bottom: ${`${FOOTER_HEIGHT_MOBILE_LANDSCAPE}px`};
    top: 30%;
    left: 12%;
    right: 12%;
    z-index: 10;
    max-width: 100%;

    &.full-overlay {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
    }
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 2px;
  padding-bottom: 2px;
  position: relative;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};

  .full-overlay & {
    height: 100%;
  }

  @media screen and (max-width: 768px) and (orientation: portrait),
  screen and (max-width: 900px) and (orientation: landscape) {
    padding: 0;
    height: 100%;
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    overflow: visible;
  }
`;

const Content = styled.div`
  position: relative;
  background-color: ${Colors.TRANSPARENT_BLUE};
  border-right: 1px solid ${Colors.TRANSPARENT_WHITE};
  border-bottom: 1px solid ${Colors.TRANSPARENT_WHITE};
  width: 100%;
  box-sizing: border-box;
  padding: calc(10px + 1vmin) calc(20px + 1vmin);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};

  .full-overlay & {
    height: 100%;
  }

  .white & {
    background-color: ${Colors.WHITE};
    border-color: ${Colors.TRANSPARENT_RED};
    color: ${Colors.DARK_BLUE};
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    border: none;
    height: 100%;
  }

  @media screen and (max-width: 900px) and (orientation: landscape){
    .full-overlay & {
      border: none;
    }
    height: 100%;
  }

  @media screen and (max-width: 425px) and (orientation: landscape) {
    border: 1px solid ${Colors.TRANSPARENT_WHITE};
  }
`;

const BorderTop = styled.div`
  border-top: 1px solid ${Colors.TRANSPARENT_WHITE};
  width: calc(100% - 4rem);
  position: absolute;
  right: 0;
  top: 0;
  
  .white & {
    border-color: ${Colors.TRANSPARENT_RED};
  }
  @media screen and (max-width: 900px) and (orientation: landscape){
    .full-overlay & {
      display: none;
    }
  }
  @media screen and (max-width: 425px) and (orientation: landscape) {
    display: none;
  }
`;

const BorderLeft = styled.div`
  border-left: 1px solid ${Colors.TRANSPARENT_WHITE};
  height: calc(100% - 4rem);
  position: absolute;
  left: 1px;
  bottom: 1px;

  .white & {
    border-color: ${Colors.TRANSPARENT_RED};
  }
  @media screen and (max-width: 900px) and (orientation: landscape){
    height: calc(100% - 4.5rem);
    left: 0;
  }
  @media screen and (max-width: 900px) and (orientation: landscape){
    .full-overlay & {
      display: none;
    }
  }
  @media screen and (max-width: 425px) and (orientation: landscape) {
    display: none;
  }
`;

const Text = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  text-align: left;

  & > p {
    margin: 0.5rem 0;
  }
  & > p:first-child {
    margin-top: 0;
  }
  & > p:last-child {
    margin-bottom: 0;
  }

  & h2 {
    color: ${Colors.RED};
    text-transform: uppercase;
    font-size: 1.1rem;
    margin: 1.25rem 0 0.5rem 0;
  }

  & h2:first-of-type {
    margin: 0 0 0.5rem 0;
  }

  & h3 {
    margin: 1rem 0 0.4rem 0;
    font-size: 1.1rem;
    text-decoration: underline;
  }

  & h4 {
    font-size: 0.9rem;
    text-decoration: underline;
    font-style: italic;
  }

  & h5 {
    font-style: italic;
    margin: 0.85rem 0 0 0;
  }

  & table {
    border-collapse: collapse;
    border: solid 1px ${Colors.DARK_BLUE};
  }

  & table tr {
    border-bottom: solid 1px ${Colors.DARK_BLUE};
  }

  & table tr > td:first-child {
    padding: 0 1rem;
  }

  & ol > li {
    margin-left: -1em;
    margin-bottom: 1rem;
  }
`;
const Icon = styled.span`
  width: calc(12px + 1vmin);
  min-height: calc(12px + 1vmin);
  margin-bottom: 1rem;
  pointer-events: none;

  & > svg {
    width: 100%;
    height: 100%;
  }

  .white & > svg * {
    stroke: #000;
  }
`;
const Corner = styled(RedCorner)`
  width: 2vmin;
  height: 2vmin;
  left: 1px;
  bottom: 1px;
  border-width: 2px;

  .full-overlay & {
    bottom: 2px;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    left: 0;
    bottom: 0;
    .full-overlay & {
      display: none;
    }
  }
`;

const CloseBT = styled.span`
  position: absolute;
  left: -4.5rem;
  top: 0;
  transform: translate(-100%, calc(-50% + 5px));
  border-radius: 50%;
  border: solid 3px ${Colors.RED};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vmin;
  height: 4vmin;
  cursor: pointer;
  background-image: radial-gradient(ellipse farthest-corner at 5px 5px, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.05) );
  
  opacity: 0;
  transition: opacity 0.25s ease-in;
  transition-delay: 0.15s;

  :hover {
    background-color: ${Colors.TRANSPARENT_BLUE};
  }

  :active {
    background-image: none;
    background-color: ${Colors.BLUE};
  }

  .white &:hover {
    background-color: ${Colors.TRANSPARENT_WHITE};
  }

  .white &:active {
    background-color: ${Colors.WHITE};
  }

  ::before {
    content: '';
    border-radius: 50%;
    background: ${Colors.RED};
    width: 6px;
    height: 6px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: 5vmin;
    height: 5vmin;
    left: -2.25rem;
    border-width: 1px;
  }

  @media screen and (max-width: 425px) and (orientation: landscape) {
    left: -0.75rem;
  }
`;
const CloseBTCross = styled(Cross)`
  width: 1.25vmin;
  height: 1.25vmin;
  pointer-events: none;
  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: 1.75vmin;
    height: 1.75vmin;
  }
`;

const ArrowCornerRed = styled(ArrowCorner)`
  @media screen and (max-width: 900px) and (orientation: landscape) {
    .full-overlay & {
      display: none;
    }
  }
`;