import styled, { css } from 'styled-components';
import { Colors } from '../../Constants';

export default styled.span`
  position: absolute;
  width: 3rem;
  height: 50%;
  transition: all 0.15s ease-in-out;
  pointer-events: none;

  ${props => props.top
    ? css `
        border-top: solid 3px ${Colors.RED};
        top: ${props.nomargin ? 0 : -1.5}px;
      `
    : css `
        border-bottom: solid 3px ${Colors.RED};
        bottom: ${props.nomargin ? 0 : -1.5}px;
      `
  }

  ${props => props.left
    ? css `
        border-left: solid 3px ${Colors.RED};
        left: ${props.nomargin ? 0 : -1.5}px;
      `
    : css `
        border-right: solid 3px ${Colors.RED};
        right: ${props.nomargin ? 0 : -1.5}px;

      `
  }
`;