import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';
import { useSpring, animated } from 'react-spring';

import ArrowCorner from './layout/ArrowCorner';
import RedCorner from './layout/RedCorner';
import { Colors } from '../Constants';
import { ReactComponent as Quote } from '../svg/quotes.svg';
import { isMobile } from 'react-device-detect';
import useDeviceOrientation from '../hooks/useDeviceOrientation';

const Captions = props => {
  const { subData, time } = props;
  const [currentSubs, setCurrentSubs] = useState([]);
  const orientation = useDeviceOrientation();

  useEffect(() => {
    const subsToShow = subData.filter(sub => sub.start <= time && sub.end >= time);
    if (!isEqual(currentSubs, subsToShow)) {
      setCurrentSubs(subsToShow);
    }
  }, [time, currentSubs, subData]);
  
  const animation = useSpring({ from: { opacity: 0, transform: `scale(0.2) ${isMobile && orientation === 'landscape' ? 'translateX(-50%)' : ''}` }, to: currentSubs.length > 0 && { opacity: 1, transform: `scale(1) ${isMobile && orientation === 'landscape' ? 'translateX(-50%)' : ''}` }, config: { duration: 150 } });
  
  return currentSubs.length > 0 && (
    <Wrapper style={animation} className='no-mobile-portrait'>
      <BorderBottom />
      <CornerTop top={true} left={true} />
      <CornerBottom className='no-mobile' />
      <Content>
        <QuoteIcon className='no-mobile' />
        { currentSubs.length > 0 && <Text>{ currentSubs.map((sub, i) => <p key={i}>{sub.text}</p>) }</Text>}
      </Content>
    </Wrapper>
  )
}
Captions.propTypes = {
  subData: PropTypes.array.isRequired,
  time: PropTypes.number.isRequired,
}
export default React.memo(Captions);

const Wrapper = styled(animated.div)`
  position: absolute;
  left: 5%;
  top: 15%;
  padding: calc(20px + 2vmin) calc(20px + 2vmin) calc(20px + 2vmin) calc(20px + 6vmin);
  width: 25%;
  max-height: 25%;
  min-height: calc(10px + 3vmin);
  height: calc(20px + calc(1 / 5vh) * 20);
  display: flex;
  flex-direction: column;
  background-color: ${Colors.TRANSPARENT_BLUE};
  border: solid 1px ${Colors.TRANSPARENT_WHITE};
  border-bottom: none;
  user-select: none;
  box-shadow: -4px -2px 10px 0px ${Colors.SHADOWS};

  transform-origin: bottom right;
  
  @media screen and (max-width: 900px) and (orientation: landscape) {
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 45%;
    padding: 2vmin 3.5vmin;
    transform-origin: bottom center;
  }
`;


const BorderBottom = styled.div`
  border-bottom: 1px solid ${Colors.TRANSPARENT_WHITE};
  width: calc(100% - 4rem);
  position: absolute;
  left: 0;
  bottom: 0;
  
  .white & {
    border-color: ${Colors.TRANSPARENT_RED};
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: 100%;
  }
`;

const CornerBottom = styled(ArrowCorner)`
  transform: rotate(-90deg) scaleY(-1) translate(calc(-100% + 8px), -10px);
  bottom: 0px;
  right: 0px;
  left: auto;
  top: auto;
`;

const CornerTop = styled(RedCorner)`
  width: 5vmin;
  height: 5vmin;
  border-width: 2px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Text = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;

  & > p {
    margin: 0.5rem 0;
  }
  & > p:first-child {
    margin-top: 0;
  }
  & > p:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    text-align: center;
    & > p {
      margin: 0.25rem 0;
    }
  }
`;

const QuoteIcon = styled(Quote)`
  width: calc(10px + 3vmin);
  position: absolute;
  top: calc(3vmin);
  left: calc(2vmin)
`;