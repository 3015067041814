import canAutoPlay from 'can-autoplay';

import button_click from './audio/button_click.mp3';
import button_appear from './audio/button_appear.mp3';
import choice_hover from './audio/choice_hover.mp3';
import choice_appear from './audio/choice_appear.mp3';
import choice_valid from './audio/choice_valid.mp3';
import start from './audio/start.mp3';
import amb_question from './audio/amb_question.mp3';
import amb_main from './audio/amb_main.mp3';
import slider from './audio/slider.mp3';
import slider_valid from './audio/slider_valid.mp3';
import popup_open from './audio/popup_open.mp3';
import { min, max } from './utils';

const currentFade = {};

const tryPlay = (audio) => {
  canAutoPlay.audio()
    .then(({ result, error }) => {
      if (result === true) {
        audio.play();
      } else {
        
      }
    })
}


const Sound = {
  AmbMain: new Audio(amb_main),
  AmbQuestion: new Audio(amb_question),
  StartBtAppear: () => tryPlay(new Audio(button_appear)),
  Click: () => tryPlay(new Audio(button_click)),
  ChoiceValid: () => tryPlay(new Audio(choice_valid)),
  ChoiceHover: () => tryPlay(new Audio(choice_hover)),
  ChoiceAppear: () => tryPlay(new Audio(choice_appear)),
  Start: () => tryPlay(new Audio(start)),
  Slider: () => tryPlay(new Audio(slider)),
  SliderValid: () => tryPlay(new Audio(slider_valid)),
  PopupOpen: () => tryPlay(new Audio(popup_open)),

  fade: (audio, out = false, to = (out ? 0 : 1)) => {
    if (!(audio instanceof Audio) || (out && audio.paused)) {
      return;
    }

    cancelAnimationFrame(currentFade[audio.currentSrc]);

    let prevTime = 0;
    const fadeVolume = (time) => {
      let step = 0;
      if (prevTime === 0) {
        step = 0.005;
      } else {
        step = (time - prevTime) * 0.0004;
      }
      prevTime = time;

      audio.volume = max(min(audio.volume + (step * (out ? -3 : 1)), out ? 1 : to), out ? to : 0);
      if (audio.volume > 0 && audio.volume < 1) {
        currentFade[audio.currentSrc] = requestAnimationFrame(fadeVolume);
      }
    };
    audio.volume = out
      ? audio.volume === 0 ? 1 : audio.volume
      : audio.volume === 1 ? 0 : audio.volume;
    tryPlay(audio);
    currentFade[audio.currentSrc] = requestAnimationFrame(fadeVolume);
  }
  
};
Sound.AmbMain.loop = true;
Sound.AmbQuestion.loop = true;
export default Sound;