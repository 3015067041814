import React from 'react'
import styled from 'styled-components';
import { Colors } from '../../Constants';

const ArrowCorner = props => {
  return (
    <Wrapper {...props} className={`${props.className} no-mobile-portrait`} viewBox="0 0 100 75">
      <path className="arrow" d="M 100,2 L 10,2 Q 0,2,10,10 L 100,75" />
      <path className="line-top" d="M 100,2 l 100,0" />
      <path className="line-left" d="M 100,75 l 0,75" />
    </Wrapper>
  )
}

export default ArrowCorner

const Wrapper = styled.svg`
  position: absolute;
  pointer-events: none;
  display: block;
  transform: translateX(-100%);
  top: -1px;
  left: 2px;
  width: 4rem;
  height: 3rem;
  overflow: visible;

  & > path {
    stroke: ${Colors.RED};
    stroke-width: 3;
    stroke-linecap: round;
  }

  & > path.arrow {
    fill: ${Colors.TRANSPARENT_BLUE};
  }

  .white & > path.arrow {
    fill: ${Colors.WHITE};
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: 2rem;
    height: 1.5rem;
    top: 0;
    left: 0;

    & > path.line-top {
      transform: scaleX(2);
      transform-origin: right;
    }
    & > path.line-left {
      transform: scaleY(2);
      transform-origin: bottom;
    }
  }

  @media screen and (max-width: 425px) and (orientation: landscape) {
    display: none !important;
  }
`;
