import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useSpring, animated } from 'react-spring';

import { ReactComponent as Outer } from '../svg/timer-outer.svg';
import { FOOTER_HEIGHT, Colors } from '../Constants';
import useAnimationFrame from '../hooks/useAnimationFrame';
import { vw } from '../utils';

function formatSeconds(value) {
  value = Math.round(value);
  return value < 10 ? `0${value}` : value.toString();
}

const Timer = props => {
  const { duration, onTimeUp, type } = props;
  const [timeCount, setTimeCount] = useState(0);
  const { t } = useTranslation('game');

  const radius = ((20 + vw(3.5)) - (5 + vw(1))) * 0.9;
  const circumference = radius * 2 * Math.PI;

  const progress = timeCount / duration;
  useAnimationFrame(deltaTime => (timeCount < duration) && setTimeCount(tc => (tc + (deltaTime * 0.001))));
  
  useEffect(() => {
    if (timeCount >= duration && typeof onTimeUp === 'function') {
      setTimeCount(0);
      onTimeUp();
    }
  }, [timeCount, onTimeUp, duration]);
  const animation = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, config: { mass: 0.5 }});

  return (
    <Wrapper type={type} style={animation}>
      { type === 'clock' && <Clock className='no-mobile'>
        <OuterClock />
        <Dotted>
          <InnerClock>
            { !isMobile && <ClockFill className='no-mobile'>
              <svg width={radius * 2} height={radius * 2} className='chart'>
                <circle r={radius} cx={radius} cy={radius} className='pie' style={{ strokeDasharray: `${(1 - progress) * circumference} ${circumference}` }} />
              </svg>
            </ClockFill> }
            <span className='number'>{formatSeconds(duration - timeCount)}</span><span style={{ zIndex: 2 }}>{ t('sec') }</span>
          </InnerClock>
        </Dotted>
      </Clock> }
      { type === 'line' && <Line className='mobile-only' style={{ right: `${(100 * progress.toFixed(2))}%` }} /> }
    </Wrapper>
  )
}
Timer.propTypes = {
  duration: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onTimeUp: PropTypes.func
};
export default Timer

const Wrapper = styled(animated.div)`
  pointer-events: none;
  user-select: none;
  
  ${props => props.type === 'clock'
    ? css`
      position: absolute;
      bottom: calc(${FOOTER_HEIGHT}px + 3vh);
      right: calc(20px + 5vw);
      width: calc(40px + 7vw);
      height: calc(40px + 7vw);
      `
    : css`
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -2px;
      left: 0;
      right: 0;
      margin: 0 !important;

      @media screen and (max-width: 900px) and (orientation: landscape) {
        height: 2px;
        bottom: -1px;
      }

      @media screen and (max-width: 425px) and (orientation: portrait) {
        height: 2px;
      }
    `}
`;

const Line = styled.div`
  background-color: ${Colors.RED};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: right 0.1s ease-in-out;
`;

const Clock = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  padding: calc(5px + 1vw);
`;

const OuterClock = styled(Outer)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%,-50%);
`;

const Dotted = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-shadow: 3px 4px 10px 0px ${Colors.SHADOWS};
`;

const InnerClock = styled.div`
  border-radius: 50%;
  border: dotted 2px ${Colors.RED};
  width: calc(100%);
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${Colors.TRANSPARENT_BLUE};

  & > .number {
    font-size: calc(14px + 3vmin);
    margin-bottom: -0.5rem;
    z-index: 2;
  }
`;

const ClockFill = styled.div`
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  
  & circle.pie {
    fill: none;
    stroke: ${Colors.RED};
    stroke-width: calc((40px + 7vw - 10px - 2vw) * 0.9);
  }

  & svg.chart {
    transform: rotate(-90deg);
    background: none;
    border-radius: 50%;
  }

`;