import { useEffect, useState } from 'react';

export default () => {
  const [orientation, setOrientation] = useState(window.screen.height > window.screen.width ? 'portrait' : 'landscape');

  const handle = e => {
    setOrientation(window.screen.height > window.screen.width ? 'portrait' : 'landscape');
  };

  useEffect(() => {
    window.addEventListener("resize", handle);
    return () => window.removeEventListener("resize", handle);
  }, []);

  return orientation;
};