import React from 'react';

export default () => <ol>
  <li>HAS-SFHTA. Prise en charge de l’hypertension artérielle de l’adulte. Fiche Memo, Septembre 2016 ;</li>
  <li>Weber MA, et al. Clinical Practice Guidelines for the Management of Hypertension in the Community A Statement by the American Society of Hypertension and the International Society of Hypertension. The Journal of Clinical Hypertension 2014;16(1):14-26 ;</li>
  <li>Mancia G, et al. 2013 ESH/ESC Guidelines for the management of arterial hypertension of the European Society of Hypertension (ESH) and of the European Society of Cardiology (ESC). Journal of Hypertension 2013,31:1281-1357 ;
  </li>
  <li>Recos ACC AHA ;</li>
  <li>HAS. Commission de la transparence ESIDREX, 6 avril 2011 ;</li>
  <li>Scheen AJ, et al. Type 2 diabetes and thiazide diuretics. Current Diabetes Reports 18:6;2018</li>
  <li>RCP ESIDREX</li>
</ol>